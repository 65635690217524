import React, { useEffect } from "react";
import "./Home.css";
import Products from "./IMG/MainProduct.webp";
import style from "./IMG/Stylyst.webp";
import Card from "../Components/Card";
import CircleText from "./CircleText/CircleText";

import Nav from "../Navigation/Navigation";

import Consult from "../Consult Form/Consult";
import Testimonials from "../Testimonials/Testimonials";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";
// Cards Imgs
import Blog from "./IMG/Blog1.webp";
import Blog2 from "./IMG/Blog2.webp";
import Blog3 from "./IMG/Blog3.webp";
import Blog4 from "./IMG/Blog4.webp";
import BlogBtn from "../Components/BlogBtn";
// For Card slider slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const dataTop = [
  {
    url: Blog,
  },
  {
    url: Blog2,
  },
  {
    url: Blog3,
  },
  {
    url: Blog4,
  },
  {
    url: Blog3,
  },
  {
    url: Blog,
  },
];
const BlogData = "Blogs";

function Home() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  let navigate = useNavigate();
  const Booking = () => {
    let path = `/BookNow`;
    navigate(path);
  };
  const Her = () => {
    let path = `/ForHer`;
    navigate(path);
  };
  const Him = () => {
    let path = `/ForHim`;
    navigate(path);
  };

  return (
    <>
      <Nav />
      {/* First Part Start */}

      <div className="Hom1">
        <div className="r765">
          <p className="t54">Welcome to SECRET17 !</p>
          <h1 className="r54">Where Beauty Meets Elegance</h1>
          <p className="w765">
            At Secret17, we believe that everyone deserves to feel confident and
            beautiful. Our talented stylists are dedicated to bringing out the
            best in you.
          </p>
          <div className="margin">
            <button onClick={Booking} className="btn-double-border-right">
              Book Now
            </button>
          </div>
        </div>
        <div className="r765FD" style={{ backgroundColor: "#222222" }}>
          <div className="fs65">
            <img className="gmi87" src={Products} alt="" />
            <div className="VFZa110p1">
              <p className="ds45"></p>
              <CircleText />
            </div>
          </div>
        </div>
      </div>
      {/* First Part End */}

      {/* Second Part Start */}
      <div className="R67oi">
        <div className="hf654" style={{ backgroundColor: "#222222" }}>
          <p className="t54yr">Welcome to SECRET17 !</p>
          <h1 className="r54se">Indulge In Luxury.</h1>
          <p className="w765gf">
            From the moment you enter our salon, you’ll feel a welcoming
            ambiance. Secret17 provides an atmosphere of utmost relaxation.
          </p>
          {/* <button onClick={Booking} className="HB654">
            Book Now
          </button> */}
          <div className="marginright">
            <button onClick={Booking} className="btn-double-border-Black">
              Book Now
            </button>
          </div>
        </div>
        <div className="hf650984">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 500"
            width="80%"
            id="blobSvg"
            // style="opacity: 1;"
            filter="blur(0px)"
            transform="rotate(0)"
          >
            <image
              x="0"
              y="0"
              width="100%"
              height="100%"
              clip-path="url(#shape)"
              href="https://images.unsplash.com/photo-1570172619644-dfd03ed5d881?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
              preserveAspectRatio="none"
            ></image>
            <clipPath id="shape">
              <path id="blob" fill="url(#gradient)">
                <animate
                  attributeName="d"
                  dur="10000ms"
                  repeatCount="indefinite"
                  values="M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;"
                ></animate>
              </path>
            </clipPath>
          </svg>
        </div>
      </div>
      {/* Second Part End */}

      {/* third Part start */}
      <div className="rvi23">
        <h1 className="mnb654">Bringing back beauty, sensationally.</h1>
        <h1 className="hn4e">Hair & Nails</h1>
        <img className="M34W" src={style} alt="" />
        {/* <button onClick={Him} className="red54r">
          For Him
        </button>
        <button onClick={Her} className="red54ghr">
          For Her
        </button> */}
        <div className="red54r">
          <button onClick={Him} className="btn-double-border-right">
            For Him
          </button>
        </div>
        <div className="red54ghr">
          <button onClick={Her} className="btn-double-border-right">
            For Her
          </button>
        </div>
      </div>
      <br />
      {/* third part END */}

      <Consult />
      <Testimonials />
      {/* <Card data={dataTop} heding={BlogData} /> */}
      {/* <BlogBtn /> */}
      <Brands />
      <Footer />
    </>
  );
}

export default Home;
