import "./App.css";
import Home from "./Component/Home/Home";
import ABoutUs from "./Component/AboutUs/AboutUs";
import ForHim from "./Component/Services/ForHim";
// import ForHim from "./Component/Services/Update/Him";
import ForHer from "./Component/Services/HerServices";
import Shop from "./Component/Shop/Shop";
import Cart from "./Component/Cart/Cart";
import Blog from "./Component/Blog/Blog";
import FullBlog from "./Component/Blog/FullBlog";
import Portfolio from "./Component/Portfolio/Portfolio";
import ContactUs from "./Component/ContactUs/ContactUs";
import BookNow from "./Component/Booking/Booknow";
import Addtocart from "./Component/AddToCart/ToCart";
import CheckOut from "./Component/CheckOut/CheckOut";
import Profile from "./Component/Profile/UserProfile";
import { Route, Routes, BrowserRouter } from "react-router-dom";
// For Card slider slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ABoutUs" element={<ABoutUs />} />
          <Route path="/ForHim" element={<ForHim />} />
          <Route path="/ForHer" element={<ForHer />} />
          <Route path="/Shop" element={<Shop />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/FullBlog" element={<FullBlog />} />
          <Route path="/Portfolio" element={<Portfolio />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/BookNow" element={<BookNow />} />
          <Route path="/Addtocart" element={<Addtocart />} />
          <Route path="/Checkout" element={<CheckOut />} />
          <Route path="/Profile" element={<Profile />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
