import React, { useState, useEffect } from "react";
import "./Portinfo.css";
import Stylest1 from "./IMG/Photos/Hair1.webp";
import Stylest2 from "./IMG/Photos/Hair2.webp";
import Stylest3 from "./IMG/Photos/Hair3.webp";
import Stylest4 from "./IMG/Photos/Hair4.webp";
import Stylest5 from "./IMG/Photos/Hair5.webp";
import Stylest6 from "./IMG/Photos/Hair6.webp";
import Stylest7 from "./IMG/Photos/Hair7.webp";
import Stylest8 from "./IMG/Photos/Hair8.webp";
import Stylest9 from "./IMG/Photos/Nail1.webp";
import Stylest10 from "./IMG/Photos/Nail2.webp";
import Stylest11 from "./IMG/Photos/Nail3.webp";
import Stylest12 from "./IMG/Photos/Nail4.webp";
import Stylest13 from "./IMG/Photos/Nail5.webp";
import Stylest14 from "./IMG/Photos/Nail6.webp";
import Stylest15 from "./IMG/Photos/Nail7.webp";
import Stylest16 from "./IMG/Photos/Nail8.webp";
import Hair1 from "./IMG/Photos/Hair9.webp";
import Hair2 from "./IMG/Photos/Hair10.webp";

function Portinfo() {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isImg, setIsImg] = useState();

  const openFullscreen = (props) => {
    setIsFullscreen(true);
    setIsImg(props);
    console.log(props);
  };

  const closeFullscreen = () => {
    setIsFullscreen(false);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Gallery-Secret17 Hair and Nail’s salon";
  }, []);
  return (
    <>
      <div className={isFullscreen ? "stop" : "start"}>
        <div className="Df53">
          <h1 className="GFHJFG081">Gallery</h1>
          <p className="POP01">HOME / Gallery</p>
        </div>
        {/* IMGS Part 1 */}
        <div className="DGHF0pzaa1l">
          <div className="Hj0p11">
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest1}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest1);
                }}
              />
            </div>
            {isFullscreen && (
              <div className="fullscreen-image" onClick={closeFullscreen}>
                <img src={isImg} alt="" />
              </div>
            )}
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest2}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest2);
                }}
              />
            </div>

            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest3}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest3);
                }}
              />
            </div>

            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest4}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest4);
                }}
              />
            </div>
          </div>
        </div>
        {/* IMGS Part 2 */}
        <div className="DGHF0pzaa1l">
          <div className="Hj0p11">
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest5}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest5);
                }}
              />
            </div>
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest6}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest6);
                }}
              />
            </div>
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest7}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest7);
                }}
              />
            </div>
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest8}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest8);
                }}
              />
            </div>
          </div>
        </div>
        {/* IMGS Part 3 */}
        <div className="DGHF0pzaa1l">
          <div className="Hj0p11">
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest9}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest9);
                }}
              />
            </div>
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest10}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest10);
                }}
              />
            </div>
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Hair2}
                alt=""
                onClick={(e) => {
                  openFullscreen(Hair2);
                }}
              />
            </div>
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest12}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest12);
                }}
              />
            </div>
          </div>
        </div>
        {/* IMGS Part 4 */}
        <div className="DGHF0pzaa1l">
          <div className="Hj0p11">
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest13}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest13);
                }}
              />
            </div>
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest14}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest14);
                }}
              />
            </div>
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Stylest15}
                alt=""
                onClick={(e) => {
                  openFullscreen(Stylest15);
                }}
              />
            </div>
            <div className="oimg_border_hover_Effect_Gallary">
              <img
                className="Vajolp"
                src={Hair1}
                alt=""
                onClick={(e) => {
                  openFullscreen(Hair1);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Portinfo;
