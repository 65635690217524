import React from "react";
import Nav from "../Navigation/Navigation";
import AddtoCart from "../AddToCart/AddToCart";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";
function ToCart() {
  return (
    <>
      <Nav />
      <AddtoCart />
      <Brands />
      <Footer />
    </>
  );
}

export default ToCart;
