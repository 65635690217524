import React from "react";
import "./Vision.css";

function Vison() {
  return (
    <>
      <div className="MailnDATA">
        <div className="margin10">
          <a className="btn-double-border-MVC">
            <h1 className="VGH001pzz">Our Mission</h1>
            <p className="Vgalo01">
            Is to not only about providing exceptional beauty services, but also about building meaningful relationships with our clients. We strive to create a warm and friendly atmosphere where our clients feel valued, heard, and appreciated. We are dedicated to exceeding our clients' expectations and building a loyal clientele who trust us with their beauty needs.
            </p>
          </a>
        </div>
        <div className="margin10">
          <a className="btn-double-border-MVC">
            <h1 className="VGH001pzz">Our Vision</h1>
            <p className="Vgalo01">
            Our vision is to continuously improve and innovate, while staying true to our values of excellence in customer care. We are committed to creating a salon experience that leaves a lasting impression on our clients, making them feel empowered, confident, and beautiful from the inside out.
            </p>
          </a>
        </div>
        <div className="margin10">
          <a className="btn-double-border-MVC">
            <h1 className="VGH001pzz">Our Values</h1>
            <p className="Vgalo01">
            Our values are the driving force behind our salon, shaping our culture, policies, and practices. We are proud to uphold our values and use them as a compass to guide us in providing exceptional beauty services, fostering inclusivity, maintaining integrity, promoting professionalism, and embracing sustainability in all aspects of our salon operations.
            </p>
          </a>
        </div>
      </div>
    </>
  );
}

export default Vison;
