import React, { useState } from "react";
import Logo from "./IMG/Group.svg";
import "./Navigation.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

function Navigation() {
  const [activeLink, setActiveLink] = useState("");
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const location = useLocation();

  const handleNavLinkClick = (linkName) => {
    setActiveLink(linkName);
  };

  const handleServicesDropdownToggle = () => {
    setIsServicesDropdownOpen((prevState) => !prevState);
  };

  const navLinks = [
    { name: "Home", path: "/", exact: true },
    { name: "About Us", path: "/AboutUs" },
    {
      name: "Services",
      path: "/Services",
      subLinks: [
        { name: "For Him", path: "/ForHim" },
        { name: "For Her", path: "/ForHer" },
      ],
    },
    { name: "Gallery", path: "/Portfolio" },
    { name: "Contact Us", path: "/ContactUs" },
  ];

  let navigate = useNavigate();
  const routePath = () => {
    let path = `/BookNow`;
    navigate(path);
  };

  return (
    <nav className="navbar navbar-expand-sm bg-white navbar-dark fixed-top border-bottom border-dark">
      <div className="container-fluid">
        <a className="navbar-brand " href="/">
          <img className="Le122" src={Logo} alt="Logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav">
            {navLinks.map((link, index) => (
              <li className="nav-item" key={index}>
                {link.subLinks ? (
                  <div
                    className={`dropdown ${
                      link.name === "Services" ? "hover-dropdown" : ""
                    }`}
                  >
                    <button className="nav-link dropdown-toggle">
                      {link.name}
                    </button>
                    <ul className="dropdown-menu">
                      {link.subLinks.map((subLink, index) => (
                        <li key={index}>
                          <NavLink
                            className="dropdown-item"
                            activeClassName="active"
                            to={subLink.path}
                            onClick={() => handleNavLinkClick(subLink.name)}
                          >
                            {subLink.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <NavLink
                    exact={link.exact}
                    className="nav-link"
                    activeClassName="active"
                    to={link.path}
                    onClick={() => handleNavLinkClick(link.name)}
                  >
                    {link.name}
                  </NavLink>
                )}
              </li>
            ))}
            <li className="nav-item">
              <button className="w2qwe3" onClick={routePath}>
                Book Now
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
