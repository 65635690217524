import React, { useState } from "react";
import "./Details.css";
import { Icon } from "@iconify/react";
import telephoneIcon from "@iconify/icons-fluent-emoji-high-contrast/telephone";
import mailRounded from "@iconify/icons-material-symbols/mail-rounded";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function ContactDetails() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Message, setMessage] = useState("");

  const [NameError, setNameError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [MobileError, setMobileError] = useState("");
  const [MessageError, setMessageError] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Check for errors
    let hasError = false;

    if (!Name.trim()) {
      setNameError("Name is required");
      hasError = true;
    } else if (!/^[a-zA-Z]+$/.test(Name)) {
      setNameError("Name should only contain alphabetic characters");
      hasError = true;
    } else if (Name.length < 3) {
      setNameError("Name should be at least 3 characters long");
      hasError = true;
    } else {
      setNameError("");
    }
    if (!Email.trim()) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
      setEmailError("Please enter a valid email address");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (!Mobile.trim()) {
      setMobileError("Mobile Number is required");
      hasError = true;
    } else if (!/^\d{10}$/.test(Mobile)) {
      setMobileError("Mobile Number should be a 10-digit number");
      hasError = true;
    } else {
      setMobileError("");
    }
    if (!Message.trim()) {
      setMessageError("Message Is Requied");
      hasError = true;
    } else {
      setMessageError("");
    }

    if (!hasError) {
      console.log("form Submited", {
        Name,
        Email,
        Mobile,
        Message,
      });
      try {
        const formData = {
          name: Name,
          email: Email,
          subject: Mobile,
          message: Message,
        };

        const formBody = new URLSearchParams(formData).toString();

        const response = await axios.post(
          "https://quarecres.mcxcontrol.com/api/secret17/secret17contact.php",
          formBody,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        if (response.status === 200) {
          // console.log("Form submitted successfully!", response.data);
          toast.success("Form submitted successfully!");
          setName("");
          setEmail("");
          setMobile("");
        } else {
          console.error("Form submission failed:", response.data);
          toast.error("Form submission failed!");
        }
      } catch (error) {
        console.error("Error sending form data:", error);
      }
    }
  };

  // Send the email

  return (
    <>
      <div className="Df53">
        <h1 className="GFHJFG081">Contact Us</h1>
        <p className="POP01">HOME / Contact Us</p>
      </div>
      <div className="O01aas">
        <div className="GFh011">
          <div className="GHGJ0p1we">
            <iframe
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              id="gmap_canvas"
              src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Secret%2017%20Hair%20And%20Nails%20Studio,%20102,%20Arista%20Bussiness%20Space%203,%20Sindhu%20Bhavan%20Marg,%20opp.%20HOF%20Showroom,%20Ahmedabad,%20Gujarat%20380054%20Ahmedabad+(home%20town)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
          <div className="GHkj001zzaq">
            <h1 className="BGh110p">Get In Touch With Us</h1>
            <form onSubmit={handleSubmit}>
              <div className="hgtfty">
                <div className="mediavisibal">
                  <div className="input-box3 active">
                    <label className="input-label">Name</label>
                    <input
                      type="text"
                      className="input-3"
                      placeholder="Enter Your Message"
                      value={Name}
                      onChange={handleNameChange}
                    />
                    {NameError && <div className="cAMQ">{NameError}</div>}
                  </div>

                  <div className="input-box3 active">
                    <label className="input-label">Phone Number</label>
                    <input
                      type="text"
                      className="input-3"
                      placeholder="Enter Your Phone Number"
                      value={Mobile}
                      onChange={handleMobileChange}
                    />
                    {MobileError && <div className="cAMQ">{MobileError}</div>}
                  </div>

                  <div className="input-box3 active">
                    <label className="input-label">Email I’d</label>
                    <input
                      type="text"
                      className="input-3"
                      placeholder="Enter Your Email I’d"
                      value={Email}
                      onChange={handleEmailChange}
                    />
                    {EmailError && <div className="cAMQ">{EmailError}</div>}
                  </div>
                </div>
                <div className="input-box3 active">
                  <label className="input-label">Message</label>
                  <input
                    type="text"
                    className="input-3"
                    placeholder="Enter Your Message"
                    value={Message}
                    onChange={handleMessageChange}
                  />
                  {MessageError && <div className="cAMQ">{MessageError}</div>}
                </div>
              </div>
              <div className="red54ghr">
                <button className="btn-double-border-Black">Submit</button>
              </div>
              <ToastContainer autoClose={3000} />
            </form>

            <div className="VFgh00p1">
              <a
                href="tel:969-965-6667"
                className="oimg_border_hover_Effect_Contact"
              >
                <Icon className="Tgg00p1" icon={telephoneIcon} />
                <p className="YHTYT12">
                  Phone: <br />
                  63573 63574
                </p>
              </a>
              <a
                href="mailto:secret7teenahm@gmail.com"
                className="oimg_border_hover_Effect_Email"
              >
                <Icon className="Tgg00p1" icon={mailRounded} />
                <p className="YHTYT12">
                  Email I’d: <br />
                  secret7teen@gmail.com
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactDetails;
