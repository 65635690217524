import React from "react";
import Nav from "../Navigation/Navigation";
import Him from "./Update/Him";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";

function ForHim() {
  return (
    <>
      <Nav />
      <Him />
      <Brands />
      <Footer />
    </>
  );
}

export default ForHim;
