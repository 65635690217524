import React, { useEffect } from "react";
import Card from "../Components/Card";
import BlogBtn from "../Components/BlogBtn";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";
// Card First
import Blog1 from "./IMGS/Blog1.png";
import Blog2 from "./IMGS/Blog2.png";
import Blog3 from "./IMGS/Blog3.png";
import Blog4 from "./IMGS/Blog4.png";
//  Card Sec
import Blog5 from "./IMGS/Blog5.png";
import Blog6 from "./IMGS/Blog6.png";
import Blog7 from "./IMGS/Blog7.png";
import Blog8 from "./IMGS/Blog8.png";
//  Card third
import Blog9 from "./IMGS/Blog9.png";
import Blog10 from "./IMGS/Blog10.png";
import Blog11 from "./IMGS/Blog11.png";
import Blog12 from "./IMGS/Blog12.png";
//  Card fourth
import Blog13 from "./IMGS/Blog13.png";
import Blog14 from "./IMGS/Blog14.png";
import Blog15 from "./IMGS/Blog15.png";
import Blog16 from "./IMGS/Blog16.png";
const dataTop = [
  {
    url: Blog1,
  },
  {
    url: Blog2,
  },
  {
    url: Blog3,
  },
  {
    url: Blog4,
  },
];
const dataSec = [
  {
    url: Blog5,
  },
  {
    url: Blog6,
  },
  {
    url: Blog7,
  },
  {
    url: Blog8,
  },
];
const datathired = [
  {
    url: Blog9,
  },
  {
    url: Blog10,
  },
  {
    url: Blog11,
  },
  {
    url: Blog12,
  },
];
const datafour = [
  {
    url: Blog13,
  },
  {
    url: Blog14,
  },
  {
    url: Blog15,
  },
  {
    url: Blog16,
  },
];

function Blogs() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="Mt67">
        <h1 className="YHt654">Blogs</h1>
        <p className="piu76">HOME / BLOGS</p>
      </div>
      <Card data={dataTop} />
      <Card data={dataSec} />
      <Card data={datathired} />
      <Card data={datafour} />
      <BlogBtn />
      <Brands />
      <Footer />
    </>
  );
}

export default Blogs;
