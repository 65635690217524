import React, { useState } from "react";
import "./Brands.css";
import brand1 from "./IMG/Brand1.jpg";
import brand2 from "./IMG/Brand2.jpg";
import brand3 from "./IMG/Brand3.jpg";
import brand4 from "./IMG/Brand4.jpg";
import brand5 from "./IMG/Brand5.jpg";
import brand6 from "./IMG/Brand6.jpg";
import brand7 from "./IMG/Brand7.jpg";
import brand8 from "./IMG/Brand8.jpg";
import brand9 from "./IMG/Brand9.jpg";
import brand10 from "./IMG/Brand10.jpg";
import brand11 from "./IMG/Brand11.jpg";
import brand12 from "./IMG/Brand12.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Brands() {
  const [Email, setEmail] = useState("");
  const [EmailError, setEmailError] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmits = async (event) => {
    event.preventDefault();

    // Check for errors
    let hasErrors = false;

    if (!Email.trim()) {
      setEmailError("Email is required");
      hasErrors = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
      setEmailError("Please enter a valid email address");
      hasErrors = true;
    } else {
      setEmailError("");
    }
    if (!hasErrors) {
      console.log("form Submited", {
        Email,
      });
      try {
        const formData = {
          email: Email,
        };

        const formBody = new URLSearchParams(formData).toString();

        const response = await axios.post(
          "https://quarecres.mcxcontrol.com/api/secret17/secret17subscribers.php",
          formBody,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        if (response.status === 200) {
          // console.log("Form submitted successfully!", response.data);
          toast.success("Form submitted successfully!");
          setEmail("");
        } else {
          console.error("Form submission failed:", response.data);
          toast.error("Form submission failed!");
        }
      } catch (error) {
        console.error("Error sending form data:", error);
      }

      setEmail("");
    }
  };
  return (
    <>
      {/* <ToastContainer /> */}
      <br />
      <div className="HBGT6">
        <h1 className="BGT4">Brands We Use</h1>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            lineHeight: "150%",
            fontFamily: "Manrope",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          Step into our sanctuary, where we hold only the best for you. After
          studying various brand reviews,
          <br /> manufacturing processes, and analysing results, we have only
          chosen the finest.
        </p>
        <div className="slider">
          <div className="slide-track">
            <div className="slide">
              <img src={brand1} className="Chweev0812" alt="" />
            </div>
            <div className="slide">
              <img src={brand2} className="Chweev0812" alt="" />
            </div>
            <div className="slide">
              <img src={brand3} className="Chweev0812" alt="" />
            </div>
            <div className="slide">
              <img src={brand4} className="Chweev0812" alt="" />
            </div>
            <div className="slide">
              <img src={brand5} className="Chweev0812" alt="" />
            </div>
            <div className="slide">
              <img src={brand6} className="Chweev0812" alt="" />
            </div>
            <div className="slide">
              <img src={brand7} className="Chweev0812" alt="" />
            </div>
            <div className="slide">
              <img src={brand8} className="Chweev0812" alt="" />
            </div>
            <div className="slide">
              <img src={brand9} className="Chweev0812" alt="" />
            </div>
            <div className="slide">
              <img src={brand10} className="Chweev0812" alt="" />
            </div>
            <div className="slide">
              <img src={brand11} className="Chweev0812" alt="" />
            </div>
            <div className="slide">
              <img src={brand12} className="Chweev0812" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="GVFR">
        <h1 className="BGTsd">Newsletter Subscription</h1>
        <p className="BHYTR">
          Join our list and hear about special deals days before
          <br />
          they go live on the website, Subscribe Now
        </p>
        <div className="GBT8">
          <form onSubmit={handleSubmits}>
            <input
              className="YHG543"
              type="text"
              name="name"
              placeholder="Enter Your Email"
              value={Email}
              onChange={handleEmailChange}
            />
            {EmailError && <div className="Baolq">{EmailError}</div>}

            <button className="btn-double-border-right">SUBMIT</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default React.memo(Brands);
