import React, { useEffect } from "react";
import Nav from "../Navigation/Navigation";
import Weare from "./WeAre/WeAre";
import Vison from "./WeAre/Vison";
import Testimonials from "../Testimonials/Testimonials";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";

function AboutUs() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "About us-Secret17 Salon in Ahmedabad";
  }, []);
  return (
    <>
      <Nav />
      <Weare />
      <Vison />
      <Testimonials />
      <Brands />
      <Footer />
    </>
  );
}

export default AboutUs;
