import React from "react";
import "./Testimonials.css";
import Mobo from "./IMG/Mobo.png";
import Client1 from "./IMG/client1.png";
import Client2 from "./IMG/client2.png";
import Client3 from "./IMG/client3.png";
import Client4 from "./IMG/clinet4.png";
import Client5 from "./IMG/clinet5.png";
import Client6 from "./IMG/clinet6.png";
import { useNavigate } from "react-router-dom";

function Testimonials() {
  let navigate = useNavigate();
  const Booking = () => {
    let path = `/BookNow`;
    navigate(path);
  };
  return (
    <>
      <div className="Red45r4">
        <div className="Yg5r" style={{ backgroundColor: "#ffffff" }}>
          <p className="Borbg1"></p>
          <p className="Borbg2"></p>
          <div className="GTfvz54">
            <h1 className="Y6t">Thanks you for making us</h1>
            <h1 className="Ygok5r">The Most Trusted Salon</h1>
            <h2 className="Y6tblplju">For Your Service !</h2>
            {/* <button onClick={Booking} className="Brfn78">
              BOOK NOW
            </button> */}
            <div className="Brfn78">
              <button onClick={Booking} className="btn-double-border-right">
                Book Now
              </button>
            </div>
          </div>
        </div>
        <div className="Yg5rd" style={{ backgroundColor: "#222222" }}>
          <h1 className="yhgt543">Client Testimonials</h1>
          <div>
            <div className="YGTdc54">
              <div className="Hbg54">
                <img className="Hbfhg564" src={Mobo} alt="" />
                <p className="Gfdv534">
                  Loved the hair color. It’s nice and trendy and vilash did a
                  great job on me and my sister in law. We are really happy and
                  would definitely recommend evryone.
                  <br />
                  <div className="Gfvc">
                    <img className="Yhbvc54" src={Client1} alt="" />
                    <p className="Vagh9170p">
                      krisha maloo
                      <br />
                      {/* CEO, Avito <br /> */}
                      <span style={{ color: "#BBA151" }}>
                        &#9733;&#9733;&#9733;&#9733;&#9733;
                      </span>
                    </p>
                  </div>
                </p>
              </div>
            </div>
            <div className="YGTdck54">
              <div className="Gvdfcd">
                <img className="Yhbv5443" src={Client2} alt="" />
                <p className="Vagh9170p">
                  Amrita Desai
                  <br />
                  {/* CEO, Avito <br /> */}
                  <span style={{ color: "#BBA151" }}>
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </span>
                  <br />I had an amazing experience with Vilash Sharma and the
                  staff. He did exactly what I wanted. Very few people
                  understand Indian hair and texture and he was definitely one
                  of them.
                </p>
              </div>
            </div>
            {/* ******************* */}
            <div className="GBVf343">
              <div className="Kmj65">
                <img className="Hbg56" src={Client4} alt="" />
                <p className="Vagh9170p">
                  anish chauhan
                  <br />
                  {/* CEO, Avito <br /> */}
                  <span style={{ color: "#BBA151" }}>
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </span>
                </p>
              </div>
              <div className="lob65">
                <p className="Hgb650p">
                  I had exquisite experience at secret17 and i would love to say
                  the girl named Nara was really good and very experienced in
                  her profession. I would definitely like to visit again..{" "}
                  <br />
                </p>
              </div>
            </div>
            <div className="YHbg543">
              <div className="YHbvcz233">
                <img className="yhy09" src={Client6} alt="" />
                <p className="Vagh9170p">
                  akhil barot
                  <br />
                  {/* CEO, Avito <br /> */}
                  <span style={{ color: "#BBA151" }}>
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </span>
                  <br />I recently had the pleasure of visiting Hair Salon
                  Secret 17, and I must say, it was an absolutely delightful
                  experience!
                </p>
              </div>
            </div>
            {/* ******************* */}
            <div className="GBVf343">
              <div className="Kmj65">
                <img className="Hbg56" src={Client5} alt="" />
                <p className="Vagh9170p">
                  Diya Brahma
                  <br />
                  {/* CEO, Avito <br /> */}
                  <span style={{ color: "#BBA151" }}>
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </span>
                </p>
              </div>
              <div className="lob65">
                <p className="Hgb650p">
                  Vijay’s service was great, he did my haircut, colour and
                  styling. I’m absolutely in love with the result. Definitely
                  recommend coming here!
                  <br />
                </p>
              </div>
            </div>
            <div className="YHbg543">
              <div className="YHbvcz233">
                <img className="yhy09" src={Client3} alt="" />
                <p className="Vagh9170p">
                  Monika Jha
                  <br />
                  {/* CEO, Avito <br /> */}
                  <span style={{ color: "#BBA151" }}>
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </span>
                  <br /> Pleasant experience for Hair Colour.. Mr Vilash is
                  Skilled in Hair transformation. My Hair turned out Exactly I
                  wanted to be. Thank you secret seventeen & team 👍
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
