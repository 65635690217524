import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import cartVariant from "@iconify/icons-mdi/cart-variant";
import heartOutline from "@iconify/icons-eva/heart-outline";
import userIcon from "@iconify/icons-mdi/user";
import "./CheckForm.css";
import { useNavigate } from "react-router-dom";

function CheckOutForm() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [Addressone, setAddressone] = useState("");
  const [Addresstwo, setAddresstwo] = useState("");
  const [Addressthree, setAddressthree] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [AddressoneError, setAddressoneError] = useState("");
  const [AddresstwoError, setAddresstwoError] = useState("");
  const [AddressthreeError, setAddressthreeError] = useState("");
  const [PincodeError, setPincodeError] = useState("");
  const [MobileError, setMobileError] = useState("");
  const [EmailError, setEmailError] = useState("");

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleAddressoneChange = (event) => {
    setAddressone(event.target.value);
  };

  const handleAddresstwoChange = (event) => {
    setAddresstwo(event.target.value);
  };

  const handleAddressthreeChange = (event) => {
    setAddressthree(event.target.value);
  };

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check for errors
    let hasError = false;

    if (!firstName.trim()) {
      setFirstNameError("First name is required");
      hasError = true;
    } else if (!/^[a-zA-Z]+$/.test(firstName)) {
      setFirstNameError("First name should only contain alphabetic characters");
      hasError = true;
    } else if (firstName.length < 3) {
      setFirstNameError("First name should be at least 3 characters long");
      hasError = true;
    } else {
      setFirstNameError("");
    }

    if (!lastName.trim()) {
      setLastNameError("Last name is required");
      hasError = true;
    } else if (!/^[a-zA-Z]+$/.test(lastName)) {
      setLastNameError("Last name should only contain alphabetic characters");
      hasError = true;
    } else if (lastName.length < 5) {
      setLastNameError("Last name should be at least 5 characters long");
      hasError = true;
    } else {
      setLastNameError("");
    }

    if (!Addressone.trim()) {
      setAddressoneError("Address Line is required");
      hasError = true;
    } else if (Addressone.length < 9) {
      setAddressoneError("Address Line should be at least 10 characters long");
      hasError = true;
    } else {
      setAddressoneError("");
    }

    if (!Addresstwo.trim()) {
      setAddresstwoError("Address Line 2 is required");
      hasError = true;
    } else if (Addresstwo.length < 9) {
      setAddresstwoError(
        "Address Line 2 should be at least 10 characters long"
      );
      hasError = true;
    } else {
      setAddresstwoError("");
    }

    if (!Addressthree.trim()) {
      setAddressthreeError("Address Line 3 is required");
      hasError = true;
    } else if (Addressthree.length < 9) {
      setAddressthreeError(
        "Address Line 3 should be at least 10 characters long"
      );
      hasError = true;
    } else {
      setAddressthreeError("");
    }

    if (!Pincode.trim()) {
      setPincodeError("Pincode is required");
      hasError = true;
    } else if (!/^\d{6}$/.test(Pincode)) {
      setPincodeError("Pincode should be a 6-digit number");
      hasError = true;
    } else {
      setPincodeError("");
    }

    if (!Mobile.trim()) {
      setMobileError("Mobile Number is required");
      hasError = true;
    } else if (!/^\d{10}$/.test(Mobile)) {
      setMobileError("Mobile Number should be a 10-digit number");
      hasError = true;
    } else {
      setMobileError("");
    }

    if (!Email.trim()) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
      setEmailError("Please enter a valid email address");
      hasError = true;
    } else {
      setEmailError("");
    }

    // Submit the form if there are no errors
    if (!hasError) {
      console.log("Submitting form", {
        firstName,
        lastName,
        Addressone,
        Addresstwo,
        Addressthree,
        Pincode,
        Mobile,
        Email,
      });
      // ...submit the form
    }
  };
  // *********
  // *********
  //Scroll To top
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  //Redirect to Profile
  let navigate = useNavigate();
  const Profile = () => {
    let path = `/Profile`;
    navigate(path);
  };
  //Validation
  return (
    <>
      <div className="Mt67">
        <h1 className="YHt654">Checkout</h1>
        <p className="piu76">HOME / SHOP/ Cart/ Checkout</p>
      </div>
      <div className="G6yhatrode">
        <input
          type="text"
          className="As312"
          placeholder="Search Items From Our Shop"
        />
        <div className="iconio77">
          <Icon className="HG0po9" icon={heartOutline} />
          <Icon className="HG0po9" icon={cartVariant} />
          <Icon onClick={Profile} className="HG0po9" icon={userIcon} />
        </div>
      </div>
      <div className="Vgfjh01p1">
        <div className="Vfg0pqq">
          <p className="QQo11p">
            <span className="vplam">`I Have a coupon?</span> Click here to enter
            your code
          </p>
        </div>
      </div>
      <div className="Volpol">
        <div className="vAPLO1">
          <div className="Vbgaopl">
            <h1 className="VBpQA">Billing details</h1>
            <div className="G10paa">
              {/* input */}
              <form onSubmit={handleSubmit}>
                <div className="input-boxcheck active">
                  <label className="input-label">First Name</label>
                  <input
                    type="text"
                    className="input-1"
                    placeholder="Enter Your First Name"
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    onChange={handleFirstNameChange}
                  />
                  {firstNameError && (
                    <div className="error">{firstNameError}</div>
                  )}
                </div>
                <div className="input-boxcheck active">
                  <label className="input-label">Last Name</label>
                  <input
                    type="text"
                    className="input-1"
                    placeholder="Enter Your Last Name"
                    name="lastName"
                    value={lastName}
                    onChange={handleLastNameChange}
                  />
                  {lastNameError && (
                    <div className="error">{lastNameError}</div>
                  )}
                </div>
                <div className="input-boxcheck active">
                  <label className="input-label"> Address Line1</label>
                  <input
                    type="text"
                    className="input-1"
                    placeholder="Enter Your Street Address"
                    name="Addressone"
                    value={Addressone}
                    onChange={handleAddressoneChange}
                  />
                  {AddressoneError && (
                    <div className="error">{AddressoneError}</div>
                  )}
                </div>
                <div className="input-boxcheck active">
                  <label className="input-label">Address Line2</label>
                  <input
                    type="text"
                    className="input-1"
                    placeholder="Enter Your apartment or flat Name"
                    name="Addresstwo"
                    value={Addresstwo}
                    onChange={handleAddresstwoChange}
                  />
                  {AddresstwoError && (
                    <div className="error">{AddresstwoError}</div>
                  )}
                </div>
                <div className="input-boxcheck active">
                  <label className="input-label">Address Line3</label>
                  <input
                    type="text"
                    className="input-1"
                    placeholder="Enter Your Town / City"
                    name="Addressthree"
                    value={Addressthree}
                    onChange={handleAddressthreeChange}
                  />
                  {AddressthreeError && (
                    <div className="error">{AddressthreeError}</div>
                  )}
                </div>
                <div className="input-boxcheck active">
                  <label className="input-label">Pincode</label>
                  <input
                    type="text"
                    className="input-1"
                    placeholder="Enter Your Area Pincode"
                    name="Pincode"
                    value={Pincode}
                    onChange={handlePincodeChange}
                  />
                  {PincodeError && <div className="error">{PincodeError}</div>}
                </div>
                <div className="input-boxcheck active">
                  <label className="input-label">Mobile Number</label>
                  <input
                    type="text"
                    className="input-1"
                    placeholder="Enter Your Mobile Number"
                    name="Mobile"
                    value={Mobile}
                    onChange={handleMobileChange}
                  />
                  {MobileError && <div className="error">{MobileError}</div>}
                </div>
                <div className="input-boxcheck active">
                  <label className="input-label">Email I'd</label>
                  <input
                    className="input-1"
                    placeholder="Enter Your Email I'd"
                    // type="email"
                    id="email"
                    value={Email}
                    onChange={handleEmailChange}
                  />
                  {EmailError && <div className="error">{EmailError}</div>}
                </div>
                <div>
                  <button className="Balodbg">Place Order</button>
                </div>
              </form>
            </div>
          </div>
          <div className="vQOP1">
            <h1 className="VBpQA">Additional information</h1>
            <div className="input-boxcheck active">
              <label className="input-label">Message</label>
              <input
                type="text"
                className="input-2"
                placeholder="Notes about your order, e.g. special notes for delivery."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="Bailoq1">
        <div className="Va10p">
          <h1 className="Vfgolp11">Your order</h1>
          <div className="Vgaoplq">
            <p>Product</p>
            <p>Subtotal</p>
          </div>
          <div className="Vgaoplq">
            <p>Bozze & Baccy Beard Oil × 1</p>
            <p>$25.00</p>
          </div>
          <div className="Vgaoplq">
            <p>Bone Super Tail Comb × 1</p>
            <p>$13.00</p>
          </div>
          <div className="Vgaoplq">
            <p>Subtotal</p>
            <p>$38.00</p>
          </div>
          <div className="Vgaoplq">
            <p>Total</p>
            <p>$38.00</p>
          </div>
          <div className="Bqqp01">
            <p>
              Your personal data will be used to process your order, support
              your experience throughout this website, and for other purposes
              described in our privacy policy.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckOutForm;
