import React from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../assets/left-arrow.svg";
import RightArrow from "../assets/right-arrow.svg";
export default function Card({ data, heding }) {
  // console.log(data, heding);
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/FullBlog`;
    navigate(path);
  };
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img src={LeftArrow} alt="prevArrow" {...props} />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img src={RightArrow} alt="nextArrow" {...props} />
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 625,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="card__container">
      <h1>{heding}</h1>
      <Slider {...settings} className="card__container--inner">
        {data.map((item, index) => {
          return (
            <div className="card__container--inner--card" key={index}>
              <img src={item.url} alt="hero_img" />

              <div className="card__container--inner--card--date_time">
                <p>Dt:-1/02/2022 04:00 PM</p>
              </div>

              <h2>
                Commemorating 50 Years of Nike, “Seen It All” Looks Boldly at
                Nike’s Next 50
              </h2>
              <p>
                The film rolls back the clock through Nike’s past as the company
                expands sport for a new generation over the next half century.
              </p>
              {/* <button onClick={routeChange} className="BThbgt543">Read More</button> */}
              <button onClick={routeChange} className="btn-double-border-right">
                Read More
              </button>
            </div>
          );
        })}
        {/* <BlogBtn /> */}
      </Slider>
    </div>
  );
}
