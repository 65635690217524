import React, { useEffect } from "react";
import "./Services.css";
import HerBanner from "./IMG/HerBanner.png";
import hr1 from "./IMG/hr2.jpg";
import st from "./IMG/portrait.jpg";
import LoginHair from "./IMG/Long Hair.jpg";
import haircolored from "./IMG/haircolored.jpg";
import HairSpa from "./IMG/Hair-spa.jpg";
import hairstyle from "./IMG/hair-style.jpg";
import Nail from "./IMG/Nail.jpg";
import manicure from "./IMG/manicure.jpg";
import blackmask from "./IMG/black-mask.jpg";
import dten from "./IMG/dten.jpg";

function Her() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "For Her-Best Hair Salon in Ahmedabad";
  }, []);
  return (
    <>
      <div className="Service-Main-Body">
        <div className="Service-Main-Body-1">
          <div className="Dammy"></div>
          <div>
            <h1 className="Title-services">Services For Her</h1>
            <p className="S-Description">
              Discover our range of services for women, including haircuts,
              colouring, styling, and treatments. Our team of experts is
              dedicated to providing you with the best possible experience,
              ensuring you leave our salon feeling confident and beautiful. Book
              your appointment today and let us take care of the rest
            </p>
            <div>
              <img className="Banner-Img-services" src={HerBanner} alt="" />
            </div>
          </div>
          {/* Services - Items Start  */}
          <div className="Services-Section">
            <div className="Ser-img">
              <img className="Img-hr" src={hr1} alt="" />
            </div>
            <div className="Ser-items">
              <h2 className="Item-heading">HAIR TREATMENT</h2>
              <p className="Item-Description">
                Pamper your hair with our specialised treatments for women.
                Whether your hair is damaged, dry, or lacking shine, our expert
                stylists will recommend the perfect treatment to restore its
                health and beauty.
              </p>
              <div className="Servic-blocks">
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={LoginHair} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">Haircut</p>
                    <ul>
                      <li className="fix-edru">Textured Layers</li>
                      <li className="fix-edru">Trending Face Framing</li>
                      <li className="fix-edru">Butterfly Cut</li>
                      <li className="fix-edru">
                        & Many more as per your expectations..
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={haircolored} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">Hair Color</p>
                    <ul>
                      <li className="fix-edru">Balayage</li>
                      <li className="fix-edru">Baby lights</li>
                      <li className="fix-edru">Ombre</li>
                      <li className="fix-edru">Highlights</li>
                    </ul>
                  </div>
                </div>
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={HairSpa} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">Care</p>
                    <ul>
                      <li className="fix-edru">Hair Spa</li>
                      <li className="fix-edru">Hair Rituals</li>
                      <li className="fix-edru">Relaxing Head Massage</li>
                    </ul>
                  </div>
                </div>
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={hairstyle} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">Treatments</p>
                    <ul>
                      <li className="fix-edru">Botox</li>
                      <li className="fix-edru">Keratin</li>
                      <li className="fix-edru">Cysteine</li>
                      <li className="fix-edru">QOD</li>
                      <li className="fix-edru">Smoothening</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Services - Items Start  */}
          {/* Services - Items Start  */}
          <div className="Services-Section">
            <div className="Ser-items">
              <h2 className="Item-heading">Nails & Skin</h2>
              <p className="Item-Description">
                Our range of services, including hand, feet, and head massages
                will leave you feeling refreshed and rejuvenated. Our
                experienced team will ensure that you receive the highest
                quality treatment in a relaxing and comfortable environment.
              </p>
              <div className="Servic-blocks">
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={Nail} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">Nails</p>
                    <ul>
                      <li className="fix-edru">Temporary Extensions</li>
                      <li className="fix-edru">Permanent Extensions</li>
                      <li className="fix-edru">Gel Polish</li>
                      <li className="fix-edru">Creative Nail Art</li>
                    </ul>
                  </div>
                </div>
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={manicure} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">Manicure & Pedicure</p>
                    <ul>
                      <li className="fix-edru">Basic</li>
                      <li className="fix-edru">Advance</li>
                      <li className="fix-edru">Luxury</li>
                    </ul>
                  </div>
                </div>
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={blackmask} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">Black Mask</p>
                    <ul>
                      <li className="fix-edru">Face & Neck</li>
                      <li className="fix-edru">Half Hands</li>
                      <li className="fix-edru">Half Legs</li>
                    </ul>
                  </div>
                </div>
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={dten} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">D- Tan</p>
                    <ul>
                      <li className="fix-edru">Face & Neck</li>
                      <li className="fix-edru">Half Hands</li>
                      <li className="fix-edru">Half Legs</li>
                      <li className="fix-edru">Back of palm</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="Ser-img">
              <img className="Img-hr" src={st} alt="" />
            </div>
          </div>
          {/* Services - Items Start  */}
        </div>
      </div>
    </>
  );
}

export default Her;
