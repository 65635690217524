import React, { useEffect } from "react";
import Nav from "../Navigation/Navigation";
import BookDetails from "./BookingDetails";

function Booknow() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Best Hair & Nail’s salon in Ahmedabad – Secret17";
  }, []);
  return (
    <>
      <Nav />
      <BookDetails />
    </>
  );
}

export default Booknow;
