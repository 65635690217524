import React from "react";
import { useNavigate } from "react-router-dom";

function BlogBtn() {
  let navigate = useNavigate();
  const ViewBlog = () => {
    let path = `/Blog`;
    navigate(path);
  };
  return (
    <>
      <div style={{ background: "#222222" }}>
        {/* <button onClick={ViewBlog} className="RTR">
          View More
        </button> */}
        <div style={{padding:'30px',}}>
          <button onClick={ViewBlog} className="btn-double-border-Black">
            View More
          </button>
        </div>
      </div>
    </>
  );
}

export default BlogBtn;
