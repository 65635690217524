import React, { useState } from "react";
import "./BookingDetails.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";
import axios from "axios";

function BookingDetails() {
  const [date, setdate] = useState(new Date().toISOString().split("T")[0]);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Dates, setDates] = useState("");
  const [Time, setTime] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedServices, setSelectedServices] = useState("");
  const [selectedCatagory, setSelectedCatagory] = useState("");
  const [subCatagory, setSubCatagory] = useState("");
  const [selectPerson, setSelectPerson] = useState("");
  const [Message, setMessage] = useState("");

  const [NameError, setNameError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [MobileError, setMobileError] = useState("");
  const [DatesError, setDatesError] = useState("");
  const year = new Date().getFullYear();
  const [TimeError, setTimeError] = useState("");
  const [selectedGenderError, setSelectedGenderError] = useState("");
  const [selectedServicesError, setSelectedServicesError] = useState("");
  const [selectedCatagoryError, setSelectedCatagoryError] = useState("");
  const [subCatagoryError, setSubCatagoryError] = useState("");
  const [selectPersonError, setSelectPersonError] = useState("");
  const [MessageError, setMessageError] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const minDate = new Date(`${year}-01-01`);
    const maxDate = new Date(`${year}-12-31`);
    const today = new Date();

    if (selectedDate < minDate || selectedDate > maxDate) {
      setDatesError("select valid date");
    } else {
      setDatesError("");
    }

    setDates(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };
  const handleselectedGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const handleSelectedServicesChange = (event) => {
    setSelectedServices(event.target.value);
  };
  const handleSelectedCatagoryChange = (event) => {
    setSelectedCatagory(event.target.value);
  };
  const handleSubCatagoryChange = (event) => {
    setSubCatagoryError(event.target.value);
  };
  const handleSelectPersonChange = (event) => {
    setSelectPersonError(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessageError(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check for errors
    let hasError = false;

    if (!Name.trim()) {
      setNameError("Name is required");
      hasError = true;
    } else if (!/^[a-zA-Z\s]+$/.test(Name)) {
      setNameError("Name should only contain alphabetic characters and spaces");
      hasError = true;
    } else if (Name.length < 3) {
      setNameError("Name should be at least 3 characters long");
      hasError = true;
    } else {
      setNameError("");
    }

    if (!Email.trim()) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
      setEmailError("Please enter a valid email address");
      hasError = true;
    } else {
      setEmailError("");
    }

    if (!Mobile.trim()) {
      setMobileError("Mobile Number is required");
      hasError = true;
    } else if (!/^\d{10}$/.test(Mobile)) {
      setMobileError("Mobile Number should be a 10-digit number");
      hasError = true;
    } else {
      setMobileError("");
    }

    if (!Dates.trim()) {
      setDatesError("Date is required");
      hasError = true;
    } else {
      setDatesError("");
    }

    if (!Time.trim()) {
      setTimeError("Time is required");
      hasError = true;
    } else {
      setTimeError("");
    }
    if (!selectedGender) {
      setSelectedGenderError("Gender is required");
      hasError = true;
    } else {
      setSelectedGenderError("");
    }
    if (!selectedServices) {
      setSelectedServicesError("Services is requied");
    } else {
      setSelectedServicesError("");
    }
    if (!selectedCatagory) {
      setSelectedCatagoryError("Category is required");
      hasError = true;
    } else {
      setSelectedCatagoryError("");
    }

    if (!subCatagory) {
      setSubCatagoryError("Subcategory is required");
      hasError = true;
    } else {
      setSubCatagoryError("");
    }

    if (!selectPerson) {
      setSelectPersonError("Service With is required");
      hasError = true;
    } else {
      setSelectPersonError("");
    }

    if (!hasError) {
      console.log("form Submited", {
        Name,
        Email,
        Mobile,
        Dates,
        Time,
        selectedGender,
        selectedServices,
        selectedCatagory,
        subCatagory,
        selectPerson,
        Message,
      });
      try {
        const formBody = new URLSearchParams({
          Name,
          Email,
          Mobile,
          Dates,
          Time,
          selectedServices,
          selectedGender,
          selectedCatagory,
          subCatagory,
          selectPerson,
          Message,
        }).toString();

        const response = await axios.post(
          "https://quarecres.mcxcontrol.com/api/secret17/booking.php",
          formBody,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        if (response.status === 200) {
          console.log("Form submitted successfully!", response.data);
          toast.success("Form submitted successfully!"); // Display success toast
          setName("");
          setEmail("");
          setMobile("");
          setDates("");
          setTime("");
          setSelectedGender("");
          setSelectedServices("");
          setSelectedCatagory("");
          setSubCatagory("");
          setSelectPerson("");
          setMessage("");
        } else {
          console.error("Form submission failed:", response.data);
          toast.error("Form submission failed!");
        }
      } catch (error) {
        console.error("Error sending form data:", error);
      }
    }
  };
  return (
    <>
      <div className="Df53">
        <h1 className="GFHJFG081">Book Now</h1>
        <p className="POP01">HOME /BOOK NOW</p>
      </div>
      <div className="Rgqol001">
        <div className="VCav01zzA">
          <form onSubmit={handleSubmit}>
            <div className="Thj0p11">
              <div className="input-box active-grey">
                <label className="input-label">Name</label>
                <input
                  type="text"
                  className="input-1"
                  placeholder="Enter Your Name "
                  id="Name"
                  name="Name"
                  value={Name}
                  onChange={handleNameChange}
                />
                {NameError && <div className="COnFom">{NameError}</div>}
              </div>
              <div className="input-box active-grey">
                <label className="input-label">Email I’d</label>
                <input
                  type="text"
                  className="input-1"
                  placeholder="Enter Your Email I’d "
                  id="email"
                  value={Email}
                  onChange={handleEmailChange}
                />
                {EmailError && <div className="COnFom">{EmailError}</div>}
              </div>
              <div className="input-box active-grey">
                <label className="input-label">Phone Number</label>
                <input
                  // type="text"
                  type="text"
                  // name="Phone Number"
                  pattern="[6-9]{1}[0-9]{9}"
                  title="Phone number with 6-9 and remaing 9 digit with 0-9"
                  className="input-1"
                  placeholder="Enter Your Phone Number"
                  name="Mobile"
                  value={Mobile}
                  onChange={handleMobileChange}
                />
                {MobileError && <div className="COnFom">{MobileError}</div>}
              </div>
              <div className="input-box active-grey">
                <label className="input-label">Gender</label>
                <select
                  className="input-1"
                  value={selectedGender}
                  onChange={(event) => setSelectedGender(event.target.value)}
                >
                  <option value="">Select Your Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
                {selectedGenderError && (
                  <div className="COnFom">{selectedGenderError}</div>
                )}
              </div>
              {/* ********************* */}
              {/* Services */}
              {/* ********************* */}
              <div className="input-box active-grey">
                <label className="input-label">Services</label>
                <select
                  className="input-1"
                  value={selectedServices}
                  onChange={(event) => setSelectedServices(event.target.value)}
                >
                  <option value="-1">Select Services</option>
                  {selectedGender === "male" ? (
                    <>
                      <option value="beard">Beard</option>
                      <option value="malehair">Hair</option>
                      <option value="Beauty">Beauty</option>
                    </>
                  ) : selectedGender === "female" ? (
                    <>
                      <option value="nail">Nail</option>
                      <option value="femalehair">Hair</option>
                      <option value="Beauty">Beauty</option>
                    </>
                  ) : null}
                </select>
                {selectedServicesError && (
                  <div className="COnFom">{selectedServicesError}</div>
                )}
              </div>
              {/* ********************* */}
              {/* Catagory */}
              {/* ********************* */}
              <div className="input-box active-grey">
                <label className="input-label">Category</label>
                <select
                  className="input-1"
                  value={selectedCatagory}
                  onChange={(event) => setSelectedCatagory(event.target.value)}
                >
                  <option value="-1">Select Category</option>
                  {selectedServices === "malehair" ? (
                    <>
                      <option value="maleHaircut">Haircut</option>
                      <option value="maleHairColours">Hair Colours</option>
                      <option value="maleRelaxing">Relaxing</option>
                      <option value="maleScalpRepair">Scalp Repair</option>
                    </>
                  ) : selectedServices === "beard" ? (
                    <>
                      <option value="BeardTrimShave">Beard Trim/Shave</option>
                      <option value="BeardColor">Beard Color</option>
                    </>
                  ) : selectedServices === "Beauty" ? (
                    <>
                      <option value="Manicure">Manicure</option>
                      <option value="Pedicure">Pedicure</option>
                      <option value="Reflexology">Reflexology</option>
                      <option value="BlackMask">BlackMask</option>
                      <option value="DTan">D-Tan</option>
                    </>
                  ) : // Nail
                    selectedServices === "nail" ? (
                      <>
                        <option value="PolishApply(HandsOrFeet)">
                          Polish Apply (Hands Or Feet)
                        </option>
                        <option value="FrenchPolish">French Polish</option>
                        <option value="NailExtension(Hands)">
                          Nail Extension (Hands)
                        </option>
                        <option value="NailExtension(Feet)">
                          Nail Extension (Feet)
                        </option>
                        <option value="removel">Removel</option>
                        <option value="NailArt(HandsOrFeet)">
                          Nail Art (Hands Or Feet)
                        </option>
                        <option value="Infills(Hands)">Infills (Hands)</option>
                        <option value="Infills(Feet)">Infills (Feet)</option>
                      </>
                    ) : // Femail Hair
                      selectedServices === "femalehair" ? (
                        <>
                          <option value="femailHaircut">Haircut</option>
                          <option value="Hairwashwithconditioner">
                            Hair wash with conditioner
                          </option>
                          <option value="BlowDry">Blow Dry</option>
                          <option value="GlobalColor">Global Color</option>
                          <option value="TouchUp">Touch Up</option>
                          <option value="Highlights">Highlights</option>
                          <option value="PreLightnings">Pre Lightnings</option>
                          <option value="Balayage">Balayage</option>
                          <option value="Ombre">Ombre</option>
                          <option value="Babylights">Baby lights</option>
                          <option value="GlobalBalayage">Global + Balayage</option>
                          <option value="OlaplexTreatment">
                            Olaplex Treatment
                          </option>
                          <option value="LorealHairSpa">Loreal Hair Spa</option>
                          <option value="HeadMassage">Head Massage</option>
                          <option value="ScalpRepair">Scalp Repair</option>
                          <option value="Moroccan">Moroccan</option>
                          <option value="TextureServices">Texture Services</option>
                        </>
                      ) : null}
                </select>
                {selectedCatagoryError && (
                  <div className="COnFom">{selectedCatagoryError}</div>
                )}
              </div>
              {/* ********************* */}
              {/* Sub Catagory */}
              {/* ********************* */}
              <div className="input-box active-grey">
                <label className="input-label">Sub-Category</label>
                <select
                  className="input-1"
                  value={subCatagory}
                  onChange={(event) => setSubCatagory(event.target.value)}
                >
                  <option value="-1">Select Sub-Category</option>
                  {/* Male Hair  */}
                  {selectedCatagory === "maleHaircut" ? (
                    <>
                      <option value="maleHaircut">
                        Child Hair Cut (up to 5 years)
                      </option>
                      <option value="HairCutbySeniorStylist">
                        Hair Cut by Senior Stylist
                      </option>
                      <option value="HairCutbyMasterStylist">
                        Hair Cut by Master Stylist
                      </option>
                      <option value="HairCutbySeniorStylist">
                        Hair Cut by Senior Stylist
                      </option>
                      <option value="HairCutbyCreativeStylist">
                        Hair Cut by Creative Stylist
                      </option>
                      <option value="Hairstyle">Hair style</option>
                      <option value="WashWithConditioner">
                        Wash With Conditioner
                      </option>
                      <option value="Moroccanwash">Moroccan wash</option>
                    </>
                  ) : selectedCatagory === "maleHairColours" ? (
                    <>
                      <option value="Perstreak">Per streak</option>
                      <option value="Global">Global</option>
                      <option value="Globaluptoneck">Global up to neck</option>
                      <option value="Highlights">Highlights</option>
                    </>
                  ) : selectedCatagory === "maleRelaxing" ? (
                    <>
                      <option value="Headmassage">Head massage</option>
                      <option value="MoroccanHeadMassage">
                        Moroccan Head Massage
                      </option>
                      <option value="LorealHairSpa">Loreal Hair Spa</option>
                      <option value="MoroccanSpa">Moroccan Spa</option>
                    </>
                  ) : selectedCatagory === "maleScalpRepair" ? (
                    <>
                      <option value="Innerspa">Inner spa </option>
                      <option value="ClearDose">
                        Clear Dose ( Anti Dandruff Treatment )
                      </option>
                    </>
                  ) : // Beard
                    selectedCatagory === "BeardTrimShave" ? (
                      <>
                        <option value="BeardTrim">Beard Trim</option>
                        <option value="BeardShave">Beard Shave</option>
                      </>
                    ) : selectedCatagory === "BeardColor" ? (
                      <>
                        <option value="BeardColor">Beard Color</option>
                      </>
                    ) : // beauty
                      selectedCatagory === "Manicure" ? (
                        <>
                          <option value="Cut & File">Cut & File</option>
                          <option value="Express">Express</option>
                          <option value="TataTan">Tata Tan</option>
                          <option value="MeBath">Me Bath</option>
                          <option value="DTanCure">D Tan Cure</option>
                          <option value="Tango">Tango</option>
                          <option value="BoxtheTox">Box the Tox</option>
                        </>
                      ) : selectedCatagory === "Pedicure" ? (
                        <>
                          <option value="PediCut & File">Cut & File</option>
                          <option value="PediExpress">Express</option>
                          <option value="PediTataTan">Tata Tan</option>
                          <option value="PediMeBath">Me Bath</option>
                          <option value="PediDTanCure">D Tan Cure</option>
                          <option value="PediTango">Tango</option>
                          <option value="PediBoxtheTox">Box the Tox</option>
                          <option value="PediHeelPeelTreatment">
                            Heel Peel Treatment
                          </option>
                        </>
                      ) : selectedCatagory === "Reflexology" ? (
                        <>
                          <option value="Feet30minutes">Feet 30 Minutes</option>
                          <option value="Hands&Feet60Minutes">
                            Hands & Feet 60 Minutes
                          </option>
                        </>
                      ) : selectedCatagory === "BlackMask" ? (
                        <>
                          <option value="Face&Neck">Face & Neck</option>
                          <option value="FeetofBackofPalm">
                            Feet of Back of Palm
                          </option>
                          <option value="HalfHands">Half Hands</option>
                          <option value="HalfLegs">Half Legs</option>
                        </>
                      ) : selectedCatagory === "DTan" ? (
                        <>
                          <option value="DTanFace&Neck">Face & Neck</option>
                          <option value="DTanFeetofBackofPalm">
                            Feet of Back of Palm
                          </option>
                          <option value="DTanHalfHands">Half Hands</option>
                          <option value="DTanHalfLegs">Half Legs</option>
                          <option value="DTanFull Hands">Full Hands</option>
                        </>
                      ) : // Nail
                        selectedCatagory === "PolishApply(HandsOrFeet)" ? (
                          <>
                            <option value="NormalPolishApply">
                              Normal Polish Apply
                            </option>
                            <option value="NormalGlitterPolish">
                              Normal Glitter Polish
                            </option>
                            <option value="NormalMattePolish">
                              Normal Matte Polish
                            </option>
                            <option value="HandGelPolish">Hand Gel Polish</option>
                            <option value="FeetGelPolish">Feet Gel Polish</option>
                            <option value="GlitterGelPolish">
                              Glitter Gel Polish
                            </option>
                            <option value="MatteGelPolish">Matte Gel Polish</option>
                            <option value="KidsPolishApply">Kids Polish Apply</option>
                            <option value="KidsStampingArt">Kids Stamping Art</option>
                          </>
                        ) : selectedCatagory === "FrenchPolish" ? (
                          <>
                            <option value="FrenchGelPolish">French Gel Polish</option>
                            <option value="FrenchGlitterGelPolish">
                              French Glitter Gel Polish
                            </option>
                            <option value="FrenchwithGelColor">
                              French with Gel Color
                            </option>
                          </>
                        ) : selectedCatagory === "NailExtension(Hands)" ? (
                          <>
                            <option value="FrenchGelPolish">
                              Temporary Nail Extension
                            </option>
                            <option value="FrenchGelPolish">Gel Overlay</option>
                            <option value="FrenchGelPolish">Acrygel Overlay</option>
                            <option value="FrenchGelPolish">Acrylic Overlay</option>
                            <option value="FrenchGelPolish">
                              Gel Nail Extension
                            </option>
                            <option value="FrenchGelPolish">
                              Acrygel Nail Extension
                            </option>
                            <option value="FrenchGelPolish">
                              Acrylic Nail Extension
                            </option>
                            <option value="FrenchGelPolish">
                              Dip Powder Nail Extension
                            </option>
                          </>
                        ) : selectedCatagory === "NailExtension(Feet)" ? (
                          <>
                            <option value="TemporaryNailExtension ">
                              Temporary Nail Extension
                            </option>
                            <option value="FrenchGelPolish">Gel Overlay</option>
                            <option value="FrenchGelPolish">Acrygel Overlay</option>
                            <option value="FrenchGelPolish">Acrylic Overlay</option>
                            <option value="FrenchGelPolish">
                              Gel Nail Extension
                            </option>
                            <option value="FrenchGelPolish">
                              Acrygel Nail Extension
                            </option>
                            <option value="FrenchGelPolish">
                              Acrylic Nail Extension
                            </option>
                          </>
                        ) : selectedCatagory === "removel" ? (
                          <>
                            <option value="Gelpolishremoval">
                              Gel polish removal
                            </option>
                            <option value="Temporaryextensionremoval">
                              Temporary extension removal
                            </option>
                            <option value="permanentextensionremoval">
                              permanent extension removal
                            </option>
                          </>
                        ) : selectedCatagory === "NailArt(HandsOrFeet)" ? (
                          <>
                            <option value="2Dnailart(pernail)">
                              2D nail art ( per nail)
                            </option>
                            <option value="3Dnailart(penail)">
                              3D nail art ( per nail)
                            </option>
                            <option value="Marblenailart(pernail)">
                              Marble nail art ( per nail)
                            </option>
                            <option value="Diamondsmall(perdiamond)">
                              Diamond - small ( per diamond)
                            </option>
                            <option value="Diamondmedium(pernail)">
                              Diamond - medium (per nail){" "}
                            </option>
                            <option value="Diamondlarge(pernail)">
                              Diamond - -large (per nail){" "}
                            </option>
                            <option value="Glitterline(pernail)">
                              Glitter line ( per nail){" "}
                            </option>
                            <option value="Stampingnailart(pernail)">
                              Stamping nail art ( per nail)
                            </option>
                            <option value="Diamondnailart(pernail)">
                              Diamond nail art ( per nail)
                            </option>
                            <option value="OmbreGelPolish">Ombre Gel Polish</option>
                            <option value="GradientGlitterwithGelColor">
                              Gradient Glitter with Gel Color
                            </option>
                            <option value="CatEyeGelColor">Cat Eye Gel Color</option>
                          </>
                        ) : selectedCatagory === "Infills(Hands)" ? (
                          <>
                            <option value="Gelinfills">Gel infills</option>
                            <option value="acrygelinfills">acrygel infills</option>
                            <option value="acrylicinfills">acrylic infills </option>
                            <option value="geloverlayinfills">
                              gel overlay infills
                            </option>
                            <option value="acrygeloverlayinfills">
                              acrygel overlay infills
                            </option>
                            <option value="acrylicoverlayinfills">
                              acrylic overlay infills
                            </option>
                            <option value="dippinginfills">dipping infills</option>
                          </>
                        ) : selectedCatagory === "Infills(Feet)" ? (
                          <>
                            <option value="Gelinfills">Gel infills</option>
                            <option value="acrygelinfills">acrygel infills</option>
                            <option value="acrylicinfills">acrylic infills </option>
                            <option value="geloverlayinfills">
                              gel overlay infills
                            </option>
                            <option value="acrygeloverlayinfills">
                              acrygel overlay infills
                            </option>
                            <option value="acrylicoverlayinfills">
                              acrylic overlay infills
                            </option>
                          </>
                        ) : // Female Hair
                          selectedCatagory === "femailHaircut" ? (
                            <>
                              <option value="ChildHairCut(upto5years)">
                                Child Hair Cut (up to 5 years)
                              </option>
                              <option value="HaircutbyMaster">
                                Hair cut by Master
                              </option>
                              <option value="HaircutbySenior">
                                Hair cut by Senior
                              </option>
                              <option value="HaircutByCreativeExpert">
                                Hair cut By Creative Expert
                              </option>
                            </>
                          ) : selectedCatagory === "Hairwashwithconditioner" ? (
                            <>
                              <option value="UptoShoulder">Up to Shoulder</option>
                              <option value="UptoShoulderWithBlowDry">
                                Up to Shoulder With Blow Dry
                              </option>
                              <option value="BelowShoulder">Below Shoulder</option>
                              <option value="BelowShoulderWithBlowDry">
                                Below Shoulder With Blow Dry
                              </option>
                              <option value="Uptowaist">Up to waist </option>
                              <option value="UptowaistWithBlowDry">
                                Up to waist With Blow Dry
                              </option>
                              <option value="Oilwash">Oil wash</option>
                            </>
                          ) : selectedCatagory === "BlowDry" ? (
                            <>
                              <option value="Blowdryin/out">Blow dry in / out</option>
                              <option value="Curles/Tongs">Curles / Tongs</option>
                              <option value="Ironing">Ironing</option>
                              <option value="Crimping">Crimping</option>
                            </>
                          ) : selectedCatagory === "GlobalColor" ? (
                            <>
                              <option value="UptoNeck">Up to Neck</option>
                              <option value="UptoShoulder">Up to Shoulder</option>
                              <option value="BelowShoulder">Below Shoulder</option>
                              <option value="UptoWaist">Up to Waist</option>
                              <option value="ColourToning">Colour Toning</option>
                            </>
                          ) : selectedCatagory === "TouchUp" ? (
                            <>
                              <option value="2inch">2 inch</option>
                              <option value="4inch">4 inch</option>
                              <option value="Hairline">Hair line</option>
                            </>
                          ) : selectedCatagory === "Highlights" ? (
                            <>
                              <option value="PerStreak">Per Streak</option>
                              <option value="FullHead">Full Head</option>
                            </>
                          ) : selectedCatagory === "PreLightnings" ? (
                            <>
                              <option value="PerStreak">Per Streak </option>
                              <option value="UptoNeck">Up to Neck</option>
                              <option value="UptoShoulder">Up to Shoulder</option>
                              <option value="BelowShoulder">Below Shoulder</option>
                              <option value="UptoWaist">Up to Waist</option>
                            </>
                          ) : selectedCatagory === "Balayage" ? (
                            <>
                              <option value="FullHead">Full Head</option>
                            </>
                          ) : selectedCatagory === "Ombre" ? (
                            <>
                              <option value="FullHead">Full Head</option>
                            </>
                          ) : selectedCatagory === "Babylights" ? (
                            <>
                              <option value="FullHead">Full Head</option>
                            </>
                          ) : selectedCatagory === "GlobalBalayage" ? (
                            <>
                              <option value="FullHead">Full Head</option>
                            </>
                          ) : selectedCatagory === "OlaplexTreatment" ? (
                            <>
                              <option value="AddOn">Add On </option>
                              <option value="Treatment">Treatment</option>
                            </>
                          ) : selectedCatagory === "LorealHairSpa" ? (
                            <>
                              <option value="UptoShoulder">Up to Shoulder</option>
                              <option value="BelowShoulder">Below Shoulder</option>
                              <option value="UptoWaist">Up to Waist</option>
                            </>
                          ) : selectedCatagory === "HeadMassage" ? (
                            <>
                              <option value="HeadMassage">Head Massage</option>
                            </>
                          ) : selectedCatagory === "ScalpRepair" ? (
                            <>
                              <option value="InnerSpa">Inner Spa</option>
                              <option value="ClearDose">
                                Clear Dose (Anti Dandruff Treatment )
                              </option>
                            </>
                          ) : selectedCatagory === "Moroccan" ? (
                            <>
                              <option value="HairWashWithConditionerUptoShoulder">
                                Hair Wash With Conditioner Up to Shoulder
                              </option>
                              <option value="HairWashWithConditionerUptoShoulderWithBlowDry">
                                Hair Wash With Conditioner Up to Shoulder With Blow Dry
                              </option>
                              <option value="HairWashWithConditionerBelowShoulder">
                                Hair Wash With Conditioner Below Shoulder
                              </option>
                              <option value="HairWashWithConditionerBelowShoulderWithBlowDry">
                                Hair Wash With Conditioner Below Shoulder With Blow Dry
                              </option>
                              <option value="HairWashWithConditionerUptoWaist">
                                Hair Wash With Conditioner Up to Waist
                              </option>
                              <option value="HairWashWithConditionerUptoWaistWithBlowDry">
                                Hair Wash With Conditioner Up to Waist With Blow Dry
                              </option>
                              <option value="HairWashWithConditionerOilWash">
                                Hair Wash With Conditioner Oil Wash
                              </option>
                              <option value="HairSpaHeadMassage">
                                Hair Spa Head Massage
                              </option>
                              <option value="HairSpaUpToShoulder">
                                Hair Spa Up To Shoulder
                              </option>
                              <option value="HairSpaBelowShoulder">
                                Hair Spa Below Shoulder
                              </option>
                              <option value="HairSpaUpToWaist">
                                Hair Spa Up To Waist
                              </option>
                            </>
                          ) : selectedCatagory === "TextureServices" ? (
                            <>
                              <option value="InnerSpa">Smoothening</option>
                              <option value="InnerSpa">Keratin</option>
                              <option value="InnerSpa">Cysteine</option>
                              <option value="InnerSpa">QOD Fiber Treatment</option>
                              <option value="InnerSpa">Botox</option>
                            </>
                          ) : null}
                </select>
                {subCatagoryError && (
                  <div className="COnFom">{subCatagoryError}</div>
                )}
              </div>
              {/* ********************* */}
              {/* ********************* */}
              <div className="input-box active-grey">
                <label className="input-label">Time</label>
                <input
                  type="Time"
                  className="input-1"
                  placeholder="Select Time "
                  id="Time"
                  name="Time"
                  value={Time}
                  onChange={handleTimeChange}
                  required
                />
              </div>
              {/* <div className="input-box active-grey">
                <label className="input-label">Service With</label>
                <select
                  className="input-1"
                  value={selectPerson}
                  onChange={(event) => setSelectPerson(event.target.value)}
                >
                  <option value="-1">Select Staff</option>
                  {selectedServices === "beard" ? (
                    <>
                      <option value="Mr.Pradip">Mr. Pradip</option>
                      <option value="Mr.Vijay">Mr. Vijay</option>
                    </>
                  ) : selectedServices === "malehair" ? (
                    <>
                      <option value="Mr.Pradip">Mr. Pradip</option>
                      <option value="Mr.Vijay">Mr. Vijay</option>
                    </>
                  ) : selectedServices === "Beauty" ? (
                    <>
                      <option value="Mr.Tia">Mr. Tia</option>
                      <option value="Ms.Naro">Ms. Naro</option>
                    </>
                  ) : selectedServices === "nail" ? (
                    <>
                      <option value="Mr.Tia">Mr. Tia</option>
                      <option value="Ms.Naro">Ms. Naro</option>
                      <option value="Mr.Suhuto">Mr. Suhuto</option>
                    </>
                  ) : selectedServices === "femalehair" ? (
                    <>
                      <option value="Mr.Pradip">Mr. Pradip</option>
                      <option value="Mr.Vijay">Mr. Vijay</option>
                    </>
                  ) : null}
                </select>
                {selectPersonError && (
                  <div className="COnFom">{selectPersonError}</div>
                )}
              </div> */}
              <div className="input-box active-grey">
                <label htmlFor="datepicker" className="input-label">
                  Date
                </label>
                <input
                  type="Date"
                  className="input-1"
                  placeholder="Enter Your Date "
                  id="Date"
                  name="Date"
                  value={Dates}
                  min={date}
                  onChange={handleDateChange}
                />
                {DatesError && <div className="COnFom">{DatesError}</div>}
              </div>
              {/* <div className="gh0p111">
                <div className="input-boxDetails active">
                  <label className="input-label">Time</label>
                  <input
                    type="Time"
                    className="input-Details"
                    placeholder="Select Time "
                    id="Time"
                    name="Time"
                    value={Time}
                    onChange={handleTimeChange}
                    required
                  />
                </div>
              </div> */}
              <div className="gh0p111">
                <div className="input-boxDetails active">
                  <label className="input-label">Message</label>
                  <input
                    type="text"
                    className="input-Details"
                    placeholder="Enter Your Message"
                    value={Message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="q1100">
              <button className="btn-double-border-Black">SUBMIT</button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer autoClose={3000} />
      <Brands />
      <Footer />
    </>
  );
}

export default BookingDetails;
