import React, { useEffect } from "react";
import "./Services.css";
import HimBanner from "./IMG/HimBanner.png";
import hr1 from "./IMG/Male1.jpg";
import Haircut from "./IMG/Haircut.jpg";
import Beard from "./IMG/Beard.jpg";
import menipedi from "./IMG/meni-pedi.jpg";
import mask from "./IMG/mask-blue-clay.jpg";

function Him() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "For Her-Best Hair Salon in Ahmedabad";
  }, []);
  return (
    <>
      <div className="Service-Main-Body">
        <div className="Service-Main-Body-1">
          <div className="Dammy"></div>
          <div>
            <h1 className="Title-services">Services For Him</h1>
            <p className="S-Description">
              Our salon offers a range of services for men, including haircuts,
              beard trims, and grooming treatments. Our skilled professionals
              specialise in creating the perfect look for every individual.
              Relax in our welcoming environment while receiving the highest
              quality services. Book your appointment today!
            </p>
            <div>
              <img className="Banner-Img-services" src={HimBanner} alt="" />
            </div>
          </div>
          {/* Services - Items Start  */}
          <div className="Services-Section">
            <div className="Ser-img">
              <img className="Img-hr" src={hr1} alt="" />
            </div>
            <div className="Ser-items">
              <h2 className="Item-heading">HAIR TREATMENT</h2>
              <p className="Item-Description">
                Monthly trim or life-changing bangs. Tell us how you feel, and
                we will take care of the rest. High street to runway, first
                dates to wedding days, we put the tool, the science and the soul
                to style.
              </p>
              <div className="Servic-blocks">
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={Haircut} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">Hair Treatments </p>
                    <ul>
                      <li className="fix-edru">Haircut</li>
                      <li className="fix-edru">Beard / Shave</li>
                      <li className="fix-edru">Global Color</li>
                      <li className="fix-edru">Highlights</li>
                    </ul>
                  </div>
                </div>
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={Beard} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">Care </p>
                    <ul>
                      <li className="fix-edru">Hair Spa</li>
                      <li className="fix-edru">Hair Rituals</li>
                      <li className="fix-edru">Relaxing Hair Spa</li>
                    </ul>
                  </div>
                </div>
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={menipedi} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">Manicure & Pedicure</p>
                    <ul>
                      <li className="fix-edru">Basic</li>
                      <li className="fix-edru">Advance</li>
                      <li className="fix-edru">Luxury </li>
                    </ul>
                  </div>
                </div>
                <div className="blocks-items">
                  <div className="Img-sec">
                    <img className="sub-set" src={mask} alt="" />
                  </div>
                  <div className="text-blokes">
                    <p className="heading-text-services">Black Mask & D-Tan</p>
                    <ul>
                      <li className="fix-edru">Face & Neck</li>
                      <li className="fix-edru">Half Hands</li>
                      <li className="fix-edru">Half Legs </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Services - Items Start  */}
        </div>
      </div>
    </>
  );
}

export default Him;
