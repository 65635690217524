import React from "react";
import "./WeAre.css";
import About1 from "./IMG/About1.png";
import About2 from "./IMG/About2.png";
import About3 from "./IMG/About3.png";
import About4 from "./IMG/About4.png";
import About5 from "./IMG/About5.png";
import About6 from "./IMG/About6.png";

function WeAre() {
  return (
    <>
      <div className="Mt67">
        <h1 className="YHt654">About Us</h1>
        <p className="piu76">HOME / About Us</p>
      </div>
      <div className="TY6655">
        <div className="yht134">
          <h2 className="SW332">Who are we?</h2>
          <p className="SD12AS">
          We are a team of passionate beauty professionals dedicated to providing exceptional services. We stay updated with the latest trends and provide inclusive services in a welcoming environment. Join us and experience a salon that cares about your well-being, embraces your uniqueness, and helps you look and feel your best.
          </p>
        </div>
        <div className="yht1345">
          <div className="oimg_border_hover_Effect">
            <img className="VFG0p1" src={About1} alt="" />
          </div>
          <div className="oimg_border_hover_Effect_CenterTB">
            <img className="VFG0p1" src={About2} alt="" />
          </div>
          <div className="oimg_border_hover_Effect">
            <img className="VFG0p1" src={About3} alt="" />
          </div>
          {/* <img className="yug908" src={About3} alt="" /> */}
        </div>
      </div>
      <div className="TY6655">
        <div className="yht13459">
          <div className="oimg_border_hover_Effect">
            <img className="VFG0p1" src={About4} alt="" />
          </div>
          <div className="oimg_border_hover_Effect_CenterTB">
            <img className="VFG0p1" src={About5} alt="" />
          </div>
          <div className="oimg_border_hover_Effect">
            <img className="VFG0p1" src={About6} alt="" />
          </div>
        </div>
        <div className="yht1349">
          <h2 className="SW332">Our story</h2>
          <p className="SD12ASd">
          Established in Ahmedabad, we are based in the heart of the most active road of our city. We wanted to provide comfort with beauty and decided to create an environment which delivers so both effortlessly.
          </p>
        </div>
      </div>
    </>
  );
}

export default WeAre;
