import React, { useEffect } from "react";
import Nav from "../Navigation/Navigation";
import Details from "./ContactDetails";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";

function ContactUs() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    document.title = "Contact us – Secret17Ahmedabad";
  }, []);
  return (
    <>
      <Nav />
      <Details />
      <Brands />
      <Footer />
    </>
  );
}

export default ContactUs;
