import React from "react";
import "./Footer.css";
import LOGO from "./IMG/Group.svg";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import callSharp from "@iconify/icons-material-symbols/call-sharp";
// import { Icon } from '@iconify/react';
import facebookIcon from "@iconify/icons-gg/facebook";
import instagramIcon from "@iconify/icons-fe/instagram";

function Footer() {
  let navigate = useNavigate();
  const FHome = () => {
    let path = `/`;
    navigate(path);
  };
  const Fhim = () => {
    let path = `/ForHim`;
    navigate(path);
  };
  const FGal = () => {
    let path = `/Portfolio`;
    navigate(path);
  };
  const FAbo = () => {
    let path = `/ABoutUs`;
    navigate(path);
  };
  const FHer = () => {
    let path = `/ForHer`;
    navigate(path);
  };
  const con = () => {
    let path = `/ContactUs`;
    navigate(path);
  };
  const FB = () => {
    window.open("https://www.facebook.com/Secret17Studio/", "_blank");
  };
  const insta = () => {
    window.open(
      "https://www.instagram.com/secret17ahmedabad/reels/?hl=en",
      "_blank"
    );
  };
  return (
    <>
      <div className="Vbaolq2">
        <div className="VGF761">
          <img className="LOFOTR" src={LOGO} alt="" />
          <p className="YH6u">
            a place where you can pamper yourself with a new look every day
          </p>
        </div>
        <div className="VGF762">
          <h2 className="YHUj65">Quick Links</h2>
          <div className="YHY6">
            <ul className="UKIO">
              <li className="uj09" onClick={FHome}>
                Home
              </li>
              <li className="uj09" onClick={Fhim}>
                For Him
              </li>
              {/* <li className="uj09">Shop</li> */}
              <li className="uj09" onClick={FGal}>
                Gallery
              </li>
            </ul>
            <ul className="UKIO">
              <li className="uj09" onClick={FAbo}>
                About Us
              </li>
              <li className="uj09" onClick={FHer}>
                For Her
              </li>
              {/* <li className="uj09">Blogs</li> */}
              <li className="uj09" onClick={con}>
                Contact Us
              </li>
            </ul>
          </div>
        </div>
        <div className="VGF763">
          <h1 className="LO98">Keep In Touch</h1>
          <div className="YJU09">
            <p className="YHJ90">
              <b>Address:</b>
              <br />
              102, Arista Business Space 3, Sindhu Bhavan Marg, opp. HOF
              Showroom, Ahmedabad, Gujarat 380054
            </p>
            <div className="Con6y766">
              <ul className="Mo809">
                <li>
                  <b>Phone:</b>
                  <br /> 063573 63574
                </li>
              </ul>
              <br />
              <ul className="Mo809">
                <li>
                  <b>Email I’d:</b>
                  <br /> secret7teen@gmail.com
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="VGF764">
          <h1 className="KLII8">Social Links</h1>
          <div className="Ico90">
            {/* <i onClick={FB} className="fa-brands fa-facebook" id="Io9"></i> */}
            <Icon onClick={FB} className="Social-Fb" icon={facebookIcon} />
            <Icon onClick={insta} className="Social-Fb" icon={instagramIcon} />
            {/* <i onClick={insta} className="fa-brands fa-instagram" id="Io8"></i> */}
            {/* <i className="fa-brands fa-twitter" id="Io7"></i> */}
          </div>
        </div>
        <div className="VGF765">
          <h1 className="TG6N">Membership Inquiries</h1>
          <h6 className="Vatr1653">Call Us On</h6>
          <a href="tel:969-965-6667" className="Gahop012Z">
            <Icon icon={callSharp} />
            &nbsp; 969-965-6667
          </a>

          {/* <h6 className="Caoolq1">Call Us On<h6/> */}
          {/* <input className="YHYT" type="text" placeholder="Enter Your Name" />
          <input
            className="YHYT"
            type="text"
            placeholder="Enter Your Phone Number"
          /> */}
          {/* <button className="UBYT">submit</button> */}
        </div>
      </div>
      <div className="Cukr">
        <p className="HGG">© 2022 Secret17 | All rights reserved</p>
      </div>
    </>
  );
}

export default Footer;
