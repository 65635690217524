import React from "react";
import Nav from "../Navigation/Navigation";
import Blogs from "./Blogs";

function Blog() {
  return (
    <>
      <Nav />
      <Blogs />
    </>
  );
}

export default Blog;
