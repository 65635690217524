import React from "react";
import Nav from "../Navigation/Navigation";
import Her from "./Update/Her";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";

function HerServices() {
  return (
    <>
      <Nav />
      <Her />
      <Brands />
      <Footer />
    </>
  );
}

export default HerServices;
