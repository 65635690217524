import {useEffect} from 'react';
import "./Fullblog.css";
import Nav from "../Navigation/Navigation";
import { Icon } from "@iconify/react";
import arrowBackRounded from "@iconify/icons-material-symbols/arrow-back-rounded";
import facebookFill from "@iconify/icons-ri/facebook-fill";
import twitterIcon from "@iconify/icons-mdi/twitter";
import linkedinFill from "@iconify/icons-akar-icons/linkedin-fill";
import instagramIcon from "@iconify/icons-mdi/instagram";
import Blog1 from "./IMGS/Blog1.png";
import blog01 from "./IMGS/details01.png";
import blog02 from "./IMGS/details02.png";
import Avatar from "./IMGS/Avatar.png";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";

function FullBlog() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Nav />
      <div className="Df53">
        <Icon className="Iconify001" icon={arrowBackRounded} />
        <h1 className="GFHJFG081">The right place for your Hairs</h1>
        <p className="POP01">
          HOME / BLOGS / HAIRS / The right place for your Hairs
        </p>
        <div className="Bgh0p11">
          <p className="DDggfal0099">Hairs</p>
          <p className="DDggfal0099">By Vatsal</p>
          <p className="DDggfal0099">Dt:-1/02/2022</p>
          <p className="DDggfal0099">04:00 PM</p>
        </div>
      </div>
      <div className="Ghh011">
        <div className="FKHkjh771">
          <img className="Maidglj011" src={Blog1} alt="" />
          <p className="For0015">
            Much obliged to you for your enthusiasm for Carlos. We realize you
            have heaps of decisions about where to purchase your provisions. We
            see the sites that are springing up everywhere throughout the web
            wilderness.
          </p>
          <p className="For0015">
            A significant number of them duplicate our web architecture and our
            item contributions.
          </p>
          <p className="For0015">
            They need to duplicate us in light of the fact that since they don’t
            know or comprehend proficient hairdresser supply. A significant
            number of these site administrators simply pursue trends attempting
            to make a speedy buck. Not us.
          </p>
          <div className="Gh0pam">
            <h1 className="Okloqq1">“</h1>
            <p className="Hbg1098">
              Facilis petentium expetenda an duo, ut pro prima homero albucius,
              illum electram an cum. Pri autem graeco mucius.
              <br />
              <span className="b0p11n">
                Lukas Rudrof
                <span style={{ letterSpacing: "0" }}>_________</span>
              </span>
            </p>
          </div>
          <p className="For0015">
            We are in the expert hairdresser supply business and have been since
            1946. Carlos has ceaselessly served the expert hairdresser by giving
            extraordinary items and administration at marvelous costs for more
            than 70 years.
          </p>
          <p className="For0015">
            At the point when the online shills proceed onward to something
            unique, we will in any case be here serving you. Our client group
            midpoints more than 14 years of administration. We are approved
            merchants for expert lines, for example, Andis, Oster, Wahl,
            Babyliss and that’s just the beginning!
          </p>
          <p className="For0015">
            A considerable lot of the online vendors professing to be approved
            merchants are most certainly not. They are moving refurbs, utilized
            stuff, or who recognizes what. We just move new, proficient devices.
            We never move returns, revamped, seconds, and so forth.
          </p>
        </div>
      </div>
      <div className="Gdjg087">
        <div className="Jsdg011">
          <img className="uldeshk" src={blog02} alt="" />
          <img className="uldeshk" src={blog01} alt="" />
          <p className="Yuiy00pq1">
            We don’t need to, we have associations with the people that make it
            and have for a long time.
          </p>
          <p className="Yuiy00pq1">
            In the event that you require benefit or to make an arrival, we will
            help you by telephone or email. You don’t need to talk with somebody
            on another landmass that has no clue what you are inquiring about.
          </p>
          <p className="Yuiy00pq1">
            We won’t request that you pick an arrival reason from a rundown of 4
            decisions that don’t make a difference.
          </p>
          <div className="HBk01">
            <img className="Yt1z" src={Avatar} alt="" />
            <div className="ghqq11">
              <h1 className="ggo0z">Richard Mason</h1>
              <p className="gghqq1">
                Hey there, My name is Richard. I love to travel and photographs.
                I take photos to keep memories alive. Blogging is an important
                part of my life since I was in high school. Welcome to my Blog!
              </p>
              <div className="Ghaoli">
                <Icon className="HYploop" icon={facebookFill} />
                <Icon className="HYploop" icon={twitterIcon} />
                <Icon className="HYploop" icon={linkedinFill} />
                <Icon className="HYploop" icon={instagramIcon} />
              </div>
            </div>
          </div>
          <div className="Onnal01">
            <h2 className="CDG090">Leave Your Thoughts Here</h2>
            <div className="input-box active">
              <label className="input-label">
                Name<span className="Hglkhj011">*</span>
              </label>
              <input
                type="text"
                className="Got091"
                placeholder="Enter Your Name"
              />
            </div>
            <div className="input-box active">
              <label className="input-label">
                EmailI'd<span className="Hglkhj011">*</span>
              </label>
              <input
                type="text"
                className="Got091"
                placeholder="Enter Your EmailI'd"
              />
            </div>
            <div className="input-box active">
              <label className="input-label">
                Comment<span className="Hglkhj011">*</span>
              </label>
              <input
                type="text"
                className="Got091"
                placeholder="Enter Your Comments"
              />
            </div>
            <ul className="Youw12">
              <li>
                Save my name, email, and website in this browser for the next
                time I comment.
              </li>
            </ul>
            <div className="KKo0pnm">
              <button className="Bnh00p">Submit</button>
            </div>
          </div>
        </div>
      </div>
      {/* <Brands />
      <Footer /> */}
    </>
  );
}

export default FullBlog;
