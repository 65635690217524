import React from "react";
import Nav from "../Navigation/Navigation";
import Profile from "./Profile"

function UserProfile() {
  return (
    <>
      <Nav />
      <Profile />
    </>
  );
}

export default UserProfile;
