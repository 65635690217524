import React from "react";
import { Icon } from "@iconify/react";
import cartVariant from "@iconify/icons-mdi/cart-variant";
import heartOutline from "@iconify/icons-eva/heart-outline";
import userIcon from "@iconify/icons-mdi/user";
import ProPic from "./IMG/Profile.png";
import "./Profile.css";

function Profile() {
  return (
    <>
      <div className="Mt67">
        <h1 className="YHt654">Profile</h1>
        <p className="piu76">HOME / SHOP/ PROFILE</p>
      </div>
      <div className="G6yhatrode">
        <input
          type="text"
          className="As312"
          placeholder="Search Items From Our Shop"
        />
        <div className="iconio77">
          <Icon className="HG0po9" icon={heartOutline} />
          <Icon className="HG0po9" icon={cartVariant} />
          <Icon className="HG0po9" icon={userIcon} />
        </div>
      </div>
      <div className="Vapl11">
        <div className="Alopq1">
          <div className="Vamop112">
            <div className="Bqolo1">
              <img className="Vol1011" src={ProPic} alt="" />
              <h3 className="BVml122">User Name</h3>
              <p className="BFh110pZX">Age, Gender</p>
            </div>
            <div className="Valopq">
              <h3 className="Balop01">Personal Details</h3>
              <h3 className="Balop01">My Orders</h3>
              <h3 className="Balop01">My Appointments</h3>
              <h3 className="Balop01">Logout</h3>
            </div>
          </div>
          <div className="KGDhgf01">
            <h1 className="Calopq1">My Orders</h1>
            <div className="Va01lZZ">
              <p className="Baolp111">Order I’d</p>
              <p className="Baolp222">Date</p>
              <p className="Baolp333">Amount</p>
              <p className="Baolp444">Status</p>
              <p className="Baolp555">Subtotal</p>
            </div>
            <div className="Va01lZZ">
              <p className="Baolp111">789-12354-2568</p>
              <p className="Baolp222">21/02/2022</p>
              <p className="Baolp333">$25.00</p>
              <p className="Baolp4444">Delivered</p>
              <p className="Baolp555">$25.00</p>
            </div>
            <div className="Va01lZZ">
              <p className="Baolp111">789-12354-2568</p>
              <p className="Baolp222">21/02/2022</p>
              <p className="Baolp333">$25.00</p>
              <p className="Baolp4444">Delivered</p>
              <p className="Baolp555">$25.00</p>
            </div>
            <div className="Va01lZZ">
              <p className="Baolp111">789-12354-2568</p>
              <p className="Baolp222">21/02/2022</p>
              <p className="Baolp333">$25.00</p>
              <p className="Baolp4444">Delivered</p>
              <p className="Baolp555">$25.00</p>
            </div>
            <div className="Va01lZZ">
              <p className="Baolp111">789-12354-2568</p>
              <p className="Baolp222">21/02/2022</p>
              <p className="Baolp333">$25.00</p>
              <p className="Baolp4444">Delivered</p>
              <p className="Baolp555">$25.00</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
