import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./CartItem.css";
// Icon
import { Icon } from "@iconify/react";
import cartVariant from "@iconify/icons-mdi/cart-variant";
import heartOutline from "@iconify/icons-eva/heart-outline";
import userIcon from "@iconify/icons-mdi/user";
// Img
import product from "./IMG/Product.png";
import Item9 from "./IMG/Item9.png";
import Item10 from "./IMG/Item10.png";
import Item11 from "./IMG/Item11.png";
import Item12 from "./IMG/Item12.png";

function CartItem() {
  let navigate = useNavigate();
  const routeCart = () => {
    let path = `/Addtocart`;
    navigate(path);
  };
  const Profile = () => {
    let path = `/Profile`;
    navigate(path);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="Mt67">
        <h1 className="YHt654">Shop</h1>
        <p className="piu76">
          HOME / SHOP/ Moustache & Beard / Bozze & Baccy Beard Oil
        </p>
      </div>
      <div className="G6yhatrode">
        <input
          type="text"
          className="As312"
          placeholder="Search Items From Our Shop"
        />
        <div className="iconio77">
          <Icon className="HG0po9" icon={heartOutline} />
          <Icon className="HG0po9" icon={cartVariant} />
          <Icon onClick={Profile} className="HG0po9" icon={userIcon} />
        </div>
      </div>
      <div className="Qpp0oNNsd">
        <div className="BV0981">
          <img className="NVCVo10p" src={product} alt="product Img" />
        </div>
        <div className="Poqqq11">
          <div className="Bv007">
            <h1 className="HGbcax">Bozze & Baccy Beard Oil </h1>
            <Icon className="BV100zza" icon={heartOutline} />
          </div>
          <p className="MVDgd001">₹25.00</p>
          <p className="NVC099871">
            Cursus nulla consectetur a eros adipiscing himenaeos nam taciti id
            turpis a scelerisque vel habitasse. Scelerisque adipiscing bibendum
            sem vestibulum et in a a a purus lectus faucibus lobortis tincidunt
            purus lectus nisl class eros.
          </p>
          <button onClick={routeCart} className="Btanhgfd">
            Add to cart
          </button>
          <p className="NVC099871"> Availability: In stock</p>
          <p className="NVC099871">Category: Moustache & Beard.</p>
          <p className="NVC099871">Tags: beard / hair / skin.</p>
        </div>
      </div>
      <div className="Fgjoli01">
        <div className="Vgfkjfgf">
          <div className="IJo09">
            <button className="H0parr15">Description</button>
            <button className="H0parr14">Reviews (0)</button>
          </div>
          <p className="VVCb001">
            Id vitae discere sit amet, nisl falli quaeque vim ut, id choro
            quaeque facilisi pro. Mei graeco vocibus cu, pro dictas assentior
            disputationi ex. Quo eu putent aliquip necessitatibus. His te mazim
            graece, cu eius rear numes mei. Mea id mollis impedit, ne mucius
            vidisse virtute vim. Nam in meis adipiscing scribentur. Id splendide
            inciderint content ones cum, in mea scripta aperiri, ne qui iusto
            mucius. Stet laudem ea has. Quo assum harum sadipscing ne, vix vidit
            idque assueverit id. Legere expetenda dissenti unt id eum, tale
            habeo recusabo at per. Cu eum saperet omittantur, cum in tritani
            alterum delicata. At oratio conte tiones mei. Vel an malis exerci,
            percipitur an.
          </p>
        </div>
      </div>
      {/* Items */}
      <h1 className="dgfh098">Related products</h1>
      <div className="itemstry1312">
        <div className="yhg0997">
          <img className="OP08765" src={Item9} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
        <div className="yhg0997">
          <img className="OP08765" src={Item10} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
        <div className="yhg0997">
          <img className="OP08765" src={Item11} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
        <div className="yhg0997">
          <img className="OP08765" src={Item12} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartItem;
