import React, { useEffect } from "react";
import "./Addtocart.css";
import { Icon } from "@iconify/react";
import cartVariant from "@iconify/icons-mdi/cart-variant";
import heartOutline from "@iconify/icons-eva/heart-outline";
import userIcon from "@iconify/icons-mdi/user";
import Product1 from "./IMG/Product1.png";
import Product2 from "./IMG/Product2.png";
import { useNavigate } from "react-router-dom";

function AddToCart() {
  let navigate = useNavigate();
  const routeCheckOut = () => {
    let path = `/Checkout`;
    navigate(path);
  };
  const Profile = () => {
    let path = `/Profile`;
    navigate(path);
  };
  const Shop = () => {
    let path = `/Shop`;
    navigate(path);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="Mt67">
        <h1 className="YHt654">Cart</h1>
        <p className="piu76">HOME / SHOP/ Cart</p>
      </div>
      <div className="G6yhatrode">
        <input
          type="text"
          className="As312"
          placeholder="Search Items From Our Shop"
        />
        <div className="iconio77">
          <Icon className="HG0po9" icon={heartOutline} />
          <Icon className="HG0po9" icon={cartVariant} />
          <Icon onClick={Profile} className="HG0po9" icon={userIcon} />
        </div>
      </div>
      <div className="Gjhg01p11">
        <div className="FJHf0p11">
          <div className="GFjk00p111">
            <div className="GHrll0111">
              <p>Product</p>
            </div>
            <div className="GHrll01112">
              <p>Price</p>
            </div>
            <div className="GHrll01113">
              <p>Quantity</p>
            </div>
            <div className="GHrll01114">
              <p>Subtotal</p>
            </div>
          </div>
          <div className="Daa1qq0p">
            <div>
              <span className="Vgf00p1">×</span>
              <img className="Bhg0p11" src={Product1} alt="" />
            </div>
            <div className="Hqop1QQ1">
              <p>Bozze & Baccy Beard Oil</p>
            </div>
            <div className="GFmkk011p">
              <p>₹25.00</p>
            </div>
            <div className="Bg0p11">
              <p>1</p>
            </div>
            <div className="B110paaZZ">
              <p>₹25.00</p>
            </div>
          </div>
          <div className="Daa1qq0p">
            <div>
              <span className="Vgf00p1">×</span>
              <img className="Bhg0p11" src={Product2} alt="" />
            </div>
            <div className="Hqop1QQ1">
              <p>Bone Super Tail Comb</p>
            </div>
            <div className="GFmkk011p">
              <p>₹13.00</p>
            </div>
            <div className="Bg0p11">
              <p>1</p>
            </div>
            <div className="B110paaZZ">
              <p>₹13.00</p>
            </div>
          </div>
          <div className="Vb0pq">
            <div>
              <input type="text" className="GD0p11" placeholder="Coupon code" />
              <button className="DZqPlzz">apply Code</button>
            </div>
            <button onClick={Shop} className="DZqPlzz">BACK TO SHOP</button>
          </div>
        </div>
      </div>
      <div className="Vgf0p1">
        <div className="Vgf0pqqm">
          <h1 className="Bpoql">Cart totals</h1>
          <div className="Vgf0p1zzA">
            <div className="VF0p1">
              <p className="Gh0pql">Subtotal</p>
              <p className="Gh0pql">$38.00</p>
            </div>
            <div className="VF0p1">
              <p className="Gh0pql">Total</p>
              <p className="Gh0pql">$38.00</p>
            </div>
          </div>
        </div>
      </div>
      <div className="VaQzzpl">
        <button onClick={routeCheckOut} className="BGplqqq01">
          Checkout
        </button>
      </div>
    </>
  );
}

export default AddToCart;
