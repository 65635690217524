import React from "react";
import Nav from "../Navigation/Navigation";
import CartItem from "./CartItem";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";

function Cart() {
  return (
    <>
      <Nav />
      <CartItem />
      <Brands />
      <Footer />
    </>
  );
}

export default Cart;
