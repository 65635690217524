import React, { useState } from "react";
import "./Consult.css";
import Secret from "./IMG/Secret.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Consult() {
  const [Name, setName] = useState("");
  const [Date, setDate] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [HairType, setHairType] = useState("");
  const [HairLength, setHairLength] = useState("");
  const [HairCondition, setHairCondition] = useState("");

  const [NameError, setNameError] = useState("");
  const [DateError, setDateError] = useState("");
  const [MobileError, setMobileError] = useState("");
  const [EmailError, setEmailError] = useState("");
  const [HairTypeError, setHairTypeError] = useState("");
  const [HairLengthError, setHairLengthError] = useState("");
  const [HairConditionError, setHairConditionError] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleMobileChange = (event) => {
    setMobile(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleHairTypeChange = (event) => {
    setHairType(event.target.value);
  };

  const handleHairLengthChange = (event) => {
    setHairLength(event.target.value);
  };

  const handleHairConditionChange = (event) => {
    setHairLength(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check for errors
    let hasError = false;

    if (!Name.trim()) {
      setNameError("Name is required");
      hasError = true;
    } else if (!/^[a-zA-Z]+$/.test(Name)) {
      setNameError("Name should only contain alphabetic characters");
      hasError = true;
    } else if (Name.length < 3) {
      setNameError("Name should be at least 3 characters long");
      hasError = true;
    } else {
      setNameError("");
    }

    if (!Date.trim()) {
      setDateError("Date of Birth is required");
      hasError = true;
    } else {
      setDateError("");
    }

    if (!Mobile.trim()) {
      setMobileError("Mobile Number is required");
      hasError = true;
    } else if (!/^\d{10}$/.test(Mobile)) {
      setMobileError("Mobile Number should be a 10-digit number");
      hasError = true;
    } else {
      setMobileError("");
    }
    if (!Email.trim()) {
      setEmailError("Email is required");
      hasError = true;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
      setEmailError("Please enter a valid email address");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (!HairType) {
      setHairTypeError("Please select your hair type");
      hasError = true;
    } else {
      setHairTypeError("");
    }

    if (!HairLength) {
      setHairLengthError("Please select your hair length");
      hasError = true;
    } else {
      setHairLengthError("");
    }

    if (!HairCondition) {
      setHairConditionError("Please select your hair Condition");
      hasError = true;
    } else {
      setHairConditionError("");
    }
    // Submit the form if there are no errors
    if (!hasError) {
      console.log("Submitting form", {
        Name,
        Date,
        Email,
        Mobile,
        HairType,
        HairLength,
        HairCondition,
      });
      try {
        const formBody = new URLSearchParams({
          Name,
          Date,
          Email,
          Mobile,
          HairType,
          HairLength,
          HairCondition,
        }).toString();

        const response = await axios.post(
          "https://quarecres.mcxcontrol.com/api/secret17/secret17Consult.php",
          formBody,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        if (response.status === 200) {
          console.log("Form submitted successfully!", response.data);
          toast.success("Form submitted successfully!"); // Display success toast
          setName("");
          setDate("");
          setEmail("");
          setMobile("");
          setHairType("");
          setHairLength("");
          setHairCondition("");
        } else {
          console.error("Form submission failed:", response.data);
          toast.error("Form submission failed!"); // Display error toast
        }
      } catch (error) {
        console.error("Error sending form data:", error);
      }
    }
  };
  return (
    <>
      <div className="Qop12">
        <h1 className="VHafs01p">
          Get a Free Hair Consultation for your New Look.
        </h1>
        <div className="FVGH10p">
          <div>
            <img className="HJDFjh011" src={Secret} alt="" />
          </div>
          <div className="V091HD">
            <form onSubmit={handleSubmit} id="form" className="ConsultForm">
              <div className="input-box active-grey">
                <label className="input-label">Name</label>
                <input
                  type="text"
                  className="input-1"
                  placeholder="Enter Your Name "
                  id="Name"
                  name="Name"
                  value={Name}
                  onChange={handleNameChange}
                />
                {NameError && <div className="COnFom">{NameError}</div>}
              </div>
              <div className="input-box active-grey">
                <label className="input-label">Date Of Birth</label>
                <input
                  type="Date"
                  className="input-1"
                  placeholder="Enter Your D.O.B "
                  id="Date"
                  name="Date"
                  value={Date}
                  onChange={handleDateChange}
                />
                {DateError && <div className="COnFom">{DateError}</div>}
              </div>
              <div className="input-box active-grey">
                <label className="input-label">Email I’d</label>
                <input
                  type="text"
                  className="input-1"
                  placeholder="Enter Your Email I’d "
                  id="email"
                  value={Email}
                  onChange={handleEmailChange}
                />
                {EmailError && <div className="COnFom">{EmailError}</div>}
              </div>
              <div className="input-box active-grey">
                <label className="input-label">Phone Number</label>
                <input
                  type="text"
                  className="input-1"
                  placeholder="Enter Your Phone Number "
                  name="Mobile"
                  value={Mobile}
                  onChange={handleMobileChange}
                />
                {MobileError && <div className="COnFom">{MobileError}</div>}
              </div>

              <div className="input-box active-grey">
                <label className="input-label">Hair Type</label>
                <select
                  className="input-1"
                  onChange={(event) => setHairType(event.target.value)}
                >
                  <option value="-1">Select Your Hair Type</option>
                  <option value="Straight Hair">Straight Hair</option>
                  <option value="Wavy Hair">Wavy Hair</option>
                  <option value="Curly Hair">Curly Hair </option>
                  <option value="Kinky Hair">Kinky Hair</option>
                </select>
                {HairTypeError && <div className="COnFom">{HairTypeError}</div>}
              </div>
              <div className="input-box active-grey">
                <label className="input-label">Hair Length</label>
                <select
                  className="input-1"
                  onChange={(event) => setHairLength(event.target.value)}
                >
                  <option value="-1">Select Your Hair Length</option>
                  <option value="Up To Neck">Up To Neck</option>
                  <option value="Below Shoulders">Below Shoulders</option>
                  <option value="Up To Waist">Up To Waist</option>
                </select>
                {HairLengthError && (
                  <div className="COnFom">{HairLengthError}</div>
                )}
              </div>
              <div className="input-box active-grey">
                <label className="input-label">Hair Condition</label>
                <select
                  className="input-1"
                  onChange={(event) => setHairCondition(event.target.value)}
                >
                  <option value="-1">Select Your Hair Condition</option>
                  <option value="Split Ends">Split Ends</option>
                  <option value="Hair Loss">Hair Loss</option>
                  <option value="Heat Damage">Heat Damage</option>
                  <option value="Frizz">Frizz</option>
                  <option value="Breakage">Breakage</option>
                  <option value="Itchy Scalp">Itchy Scalp</option>
                  <option value="Dry Hair">Dry Hair </option>
                  <option value="Dandruff">Dandruff</option>
                  <option value="Transitioning">Transitioning</option>
                  <option value="Healthy">Healthy</option>
                </select>
                {HairConditionError && (
                  <div className="COnFom">{HairConditionError}</div>
                )}
              </div>
              <div className="input-box active-grey">
                <label className="input-label">Additional Quarries </label>
                <input
                  type="text"
                  className="input-1"
                  placeholder="Write Your Quarries"
                />
              </div>
              {/* <div className="JFG011">
                <button className="HFDgcbtn123"> Submit </button>
              </div> */}
              <div className="JFG011">
                <button className="btn-double-border-Black" type="submit">
                  Book Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default Consult;
