import React from "react";
import "./Items.css";
import { Icon } from "@iconify/react";
import cartVariant from "@iconify/icons-mdi/cart-variant";
import heartOutline from "@iconify/icons-eva/heart-outline";
import userIcon from "@iconify/icons-mdi/user";
// Items Imgs
import Item1 from "./IMG/Item1.png";
import Item2 from "./IMG/Item2.png";
import Item3 from "./IMG/Item3.png";
import Item4 from "./IMG/Item4.png";
import Item5 from "./IMG/Item5.png";
import Item6 from "./IMG/Item6.png";
import Item7 from "./IMG/Item7.png";
import Item8 from "./IMG/Item8.png";
import Item9 from "./IMG/Item9.png";
import Item10 from "./IMG/Item10.png";
import Item11 from "./IMG/Item11.png";
import Item12 from "./IMG/Item12.png";
import { useNavigate } from "react-router-dom";
// Items Imgs

function ShopItems() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/Cart`;
    navigate(path);
  };
  const Profile = () => {
    let path = `/Profile`;
    navigate(path);
  };
  return (
    <>
      <div className="Mt67">
        <h1 className="YHt654">Shop</h1>
        <p className="piu76">HOME / SHOP</p>
      </div>
      <div className="G6yhatrode">
        <input
          type="text"
          className="As312"
          placeholder="Search Items From Our Shop"
        />
        <div className="iconio77">
          <Icon className="HG0po9" icon={heartOutline} />
          <Icon className="HG0po9" icon={cartVariant} />
          <Icon onClick={Profile} className="HG0po9" icon={userIcon} />
        </div>
      </div>
      {/* Items */}
      <div className="itemstry1312">
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item1} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00 </p>
          </div>
        </div>
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item2} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item3} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item4} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
      </div>{" "}
      {/* Items */}
      <div className="itemstry1312">
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item5} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item6} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item7} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item8} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
      </div>
      {/* Items */}
      <div className="itemstry1312">
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item9} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item10} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item11} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
        <div className="yhg0997" onClick={routeChange}>
          <img className="OP08765" src={Item12} alt="" />
          <div className="JH0912zzasx">
            <p>American Crew Beard Serum</p>
            <p className="IUDGt09z0">₹15.00</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShopItems;
